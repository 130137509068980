import React from 'react';
import {FaArrowRight} from "react-icons/fa"


import Blacknavbar from '../../blackNavbar';
import BacktoTop from '../../Navbar/backtoTop';

import Retan from '../return';

import standard from "./roomImages/standard/std1.jpg";
import double from "./roomImages/double/dbr1.jpg";
import tripple from "./roomImages/tripple/tpr1.jpg";
import Footer from '../../footer';



import { Link } from 'react-router-dom'

export default function Allrooms() {
  return (
    <main>
      <Blacknavbar/>
      <BacktoTop/>
      <Retan/>
      <div className='our-houses' data-aos="zoom-in">
        <h2 className='hseeeH1' data-aos="zoom-in">Our Rooms</h2>  
        <div  className='hseee'></div>
      </div> 
      <section className='allhse-section'>
        <div className='the-allhse' data-aos="flip-up">
          <img src={standard} className='the-allhse-img'/>
          <h4>Single Room with Balcony View</h4>
          <div className='hgt-inc'>
            <span className='span-yw'>per night</span><br/>
            <span>35 USD - 1 guest</span>
            
          </div>
          <Link to="/Houses/Book-room/roomFile/stand-room" className='view-li'>See Details</Link>
        </div>
        <div className='the-allhse' data-aos="flip-up">
          <img src={double} className='the-allhse-img'/>
          <h4>King Room with Balcony View</h4>
          <div className='hgt-inc'>
            <span className='span-yw'>per night</span><br/>
            <span>36 USD - 1 guests</span><br/>
            <span>40 USD - 2 guests</span>
          </div>
          <Link to="/Houses/Book-room/roomFile/db-room" className='view-li'>See Details</Link>

        </div>

        <div className='the-allhse' data-aos="flip-up">
          <img src={tripple} className='the-allhse-img'/>
          <h4>Tripple Room</h4>
          <div className='hgt-inc'>
            <span className='span-yw'>per night</span><br/>
            <span>24 USD - 1 guest</span><br/>
            <span>27 USD - 2 guests</span><br/>
            <span>30 USD - 3 guests</span>
          </div>
          <Link to="/Houses/Book-room/roomFile/tp-room" className='view-li'>See Details</Link>
        </div>
      </section>

      <div className='change-houses' data-aos="zoom-in">
      <Link to="/Houses/Book-house/all-house" className='change-houses-link'>
      <button><span className='tyyyy'>Go To Houses</span> <FaArrowRight className='moveit'/> </button></Link>
      </div>
      <Footer/>
    </main>
  )
}