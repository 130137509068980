import React from 'react';

import { Link } from 'react-router-dom'

import ketLogo from "./Ketlogo.png"


export default function Retan() {
  return (
    <main className='mn-sub-bar'>
      <div className='sub-bar'>
        <div className='sub-bar-logo'>
          <Link to="/">
            <img src={ketLogo} alt='not found' className='ket-logo'/>
          </Link>
          </div>
        <Link to="/" className='return-btn'>Home</Link>
              {/* <button>Return Home</button> */}
      </div>
    </main>
  )
    
}