import React from 'react';

import {FaLinkedin, FaTwitter, FaPhone, FaEnvelope, FaTiktok, FaMapPin, FaInstagram} from "react-icons/fa"


export default function Blacknavbar() {

  return (
    <section className='Header-handles'>
        <div className='subheader-handles'>
          <div className='first-handles'>
            <div className='handle-text'>
              <span className='handle-Fa'><FaMapPin/></span>
              <span>Lemara, Njiro Arusha Tanzania</span>
            </div>
            <div className='handle-text'>
              <span className='handle-Fa'><FaEnvelope/></span>
              <span>karibu@mamakethomes.co.tz</span>
            </div>
            <div className='handle-text'>
              <span className='handle-Fa'><FaPhone/></span>
              <span>+255 717 112 802</span>
            </div>
          </div>

          <div className='second-handles'>
            <FaTiktok/>
            <FaTwitter/>
            <FaLinkedin/>
            <FaInstagram/>

          </div>
        </div>

      </section>
  )
}