import React from 'react';

import {FaClock,FaPhone, FaEnvelope, FaMapMarker} from "react-icons/fa"
import axios from 'axios';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
  let [secvalue, setSec] = React.useState(0)

  // let seconds = 0;
  

  // let interval = null
  // let currentState = "stopped"

  // function timer() {
  //   seconds += 1
  //   // if (seconds / 60 === 1) {
  //   //     seconds = 0
  //   // }
  //   setSec(seconds)
    

  // }
  // console.log(secvalue)

  // if(secvalue === 5) {
  //   document.querySelector(".refresh-btn").click()
  // }


  // console.log(seconds)
  const [clientName, setclientName] = React.useState("");
  const [clientEmail, setclientMail] = React.useState("");
  const [enquiry, setEnquiry] = React.useState("");
  const [thecomment, setctComment] = React.useState("");

  // prevent feedback notification

  const [notify, setNote] = React.useState(true)


  const [responseData, setResponse] = React.useState({
    enterName: "",
    email: "",
    messageType: "",
    comment: "",
    

  })

  function GrabInputs(event) {
      setResponse(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
  }

  React.useEffect(()=> {
    setclientName(responseData.enterName)
    setclientMail(responseData.email);
    setEnquiry(responseData.messageType);
    setctComment(responseData.comment);

    if(clientEmail !== "" && clientName !== "") {
      setNote(true)
    } else {
      setNote(false)
    }
  })

  // let mail = ["nicksonnyongesa73@gmail.com", "festasotieno97@gmail.com"]
  let mail = ["enquire@mamaket.afroeuro.ltd"]

  let nem = "Mama-KET Home"

  function sendMail(event) {
    event.preventDefault()
    axios.get("https://kettwo.afroeuro.ltd/clientEnquiry", {
    // axios.get("http://localhost:8081/clientEnquiry", {
      params: {
        mail,
        nem,
        clientName,
        clientEmail,
        enquiry,
        thecomment,

      },
    })
    .then(() => {
      // console.log("success");
    })
    .catch(() => {
      // console.log("email not sent");
      // console.log("");

    })

    setResponse(
      {
        enterName: "",
        email: "",
        messageType: "",
        comment: "",
      }
    )
    toast.success('🦄 Message Sent!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // transition: Bounce,
      });

      // interval = window.setInterval(timer, 1000)

  }

  return <main className='hm-contact'>
    <section className='hm-contact'>
      <div className='contact-text' data-aos="fade-up"
      data-aos-duration="3000">
        <h1>Contact Info</h1>
        <div className='c-underscore'></div>
        <p className='for-distance'>Mama Ket Homes is located in the serene neighborhood of Lemara, Njiro, Arusha. Just 10 minutes by car or 30 minutes by foot from the city center of Arusha.</p>
        <p>For booking enquiries, please reach out to our reservations team in Arusha, Tanzania.</p>
        <div className='sub-contact'>
          <div className='sub-sub-contact'>
            <FaClock className='sub-contact-fa'/>
            <div>
              <h4>Working Hours</h4>
              <span>24/7 Hours</span>
            </div>
          </div>
          <div className='sub-sub-contact'>
          <FaMapMarker className='sub-contact-fa'/>
            <div>
              <h4>Our Office Address</h4>
              <span>Lemara Road, Njiro Arusha Tanzania</span>
            </div>
          </div>
          <div className='sub-sub-contact'>
          <FaPhone className='sub-contact-fa'/>
            <div>
              <h4>Phone Number</h4>
              <span>+255 717 112 802</span>
            </div>
          </div>
          <div className='sub-sub-contact'>
          <FaEnvelope className='sub-contact-fa'/>
            <div>
              <h4>Email</h4>
              <span>karibu@mamakethomes.co.tz</span>
            </div>
          </div>

        </div>
      </div>
      <div className='contact-mmForm' data-aos="flip-down">
        
        <form onSubmit={sendMail}>
        <aside className="input-div">
          <label>Name</label>
          <input 
              type="text"
              placeholder="Enter Name"
              name="enterName"
              onChange={GrabInputs}
              value={responseData.enterName}
          />

          <label htmlFor='message'>Subject</label>
          <select className='message'
          id='messageType'
            value={responseData.message}
            name="messageType"
            onChange={GrabInputs}
          >
            <option value="accomodations">--select--</option>
            <option value="accomodations">Accomodations</option>
            <option value="feedback">Feedback/Suggestions</option>
            <option value="enquiry">General-Enquiry</option>
            <option value="Other">Other</option>

          </select>
          <label>Email</label>
          <input 
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={GrabInputs}
              value={responseData.email}
          />

          <label>Message</label>
          <textarea 
              placeholder="Your Message"
              name="comment"
              onChange={GrabInputs}
              value={responseData.comment}
              rows="5"

          />
          <button type="submit" className="formBtn">Send Message</button>

        </aside>
        </form>
        
        <form>
          {/* <button className='refresh-btn'>rtty</button> */}
        </form>
      </div>
    </section>
    

    {notify ? <ToastContainer />: "" }
    
  </main>
}