import React from 'react';

import standard from "./roomImages/standard.jpg"
import double from "./roomImages/double.jpg"
import tripple from "./roomImages/tripple.jpg"

import { FaRegSquare } from "react-icons/fa";
import { Link } from 'react-router-dom'


export default function Ourrooms() {
  return (
    <main className='roomsMain'>
      <div className="comfort">
        <p>Your Family's Comfort, Our Priority in the Heart of Arusha.</p>
        <h2 data-aos="zoom-in">Our Rooms</h2>
        <div className='rm-underscore' data-aos="zoom-in"></div>
      </div>
      <div className="Our-rooms">
        <aside 
          data-aos="fade-up"
          data-aos-delay = "0"
          className='individual-room'>
          <img src={standard} alt='not found'/>
          <h4 className='faded-blue'>Single Room with Balcony View</h4>
          <span>per night</span>
          <span>35 USD - 1 guest</span>

          <Link to="/Houses/Book-room/roomFile/stand-room">
            <button className="room-btn dddcj">See Details</button>
          </Link>
        </aside>
        <aside 
          data-aos="fade-up"
          data-aos-delay = "200"
          className='individual-room'>
          <img src={double} alt='not found'/>
          <h4 className='faded-blue'>King Room with Balcony View</h4>
          <span>per night</span>
          <span>36 USD - 1 guests</span>
          <span>40 USD - 2 guests</span>

          <Link to="/Houses/Book-room/roomFile/db-room">
            <button className="room-btn dddcj">See Details</button>
          </Link>

        </aside>
        <aside 
          data-aos="fade-up"
          data-aos-delay = "400"
          className='individual-room'>
          <img src={tripple} alt='not found'/>
          <h4 className='faded-blue'>Triple Room</h4>
          <span>per night</span>
          <span>24 USD - 1 guest</span>
          <span>27 USD - 2 guests</span>
          <span>30 USD - 3 guests</span>

          <Link to="/Houses/Book-room/roomFile/tp-room">
            <button className="room-btn dddcj">See Details</button>
          </Link>

        </aside>
      </div>
      {/* <div className='sq-btnDiv'>
        <Link to="/Houses/Book-room/homePageBooking">
          <button className="room-btn dddcj">< FaRegSquare className='fa-sqaure'/> BOOK A ROOM</button>
        </Link>
      </div> */}
    </main>
  )
} 