import React from 'react';

import AOS from "aos";
import "aos/dist/aos.css";

import Blacknavbar from './blackNavbar';
import Navigation from './Navbar/navbar-home';
import BacktoTop from './Navbar/backtoTop';
import Mamaket from './Elements/mamaKet';
import Ourrooms from './Elements/Ourrooms/rooms';
import Mesahouse from './Elements/Mesahouse/mesahouse';
import Amenities from './Elements/Amenities/amenities';
import Gallery from './Elements/Gallery/gallery';
import Bestexpi from './Elements/BestExpi/bestExpi';
import Contact from './Elements/contact';
import Footer from './footer';

export default function Home() {
  React.useEffect(()=> {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, [])
  return (
    <main>
      
      <Blacknavbar/>
      <BacktoTop/>
      <Navigation/>
      <Mamaket/>
      <Ourrooms/>
      <Mesahouse/>
      <Amenities/>
      <Gallery/>
      <Bestexpi/>
      <Contact/>
      <Footer/>
    </main>
  )
} 