import React from 'react';

import axios from 'axios';

import BacktoTop from '../../Navbar/backtoTop';
import Footer from '../../footer';
import {FaAngleRight} from "react-icons/fa";

import { Link } from 'react-router-dom';
import {FaArrowRight} from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function QuickBooking() {
  const [notifyP, setNoteP] = React.useState(true)

  // let [secvaluePG, setSec] = React.useState(0)

  // let seconds = 0;
  

  // let interval = null
  // let currentState = "stopped"

  // function timer() {
  //   seconds += 1
  //   // if (seconds / 60 === 1) {
  //   //     seconds = 0
  //   // }
  //   setSec(seconds)
    

  // }
  // console.log(secvalue)

  // if(secvaluePG === 6) {
  //   document.querySelector(".refresh-btnB").click()
  // }

  // const {makeOder, setOder} = useGlobalContext();

  const [userMail, setMail] = React.useState("");
  const [reservedDays, setrsDays] = React.useState(0);
  const [roomType, setRoom] = React.useState("");
  const [numOfPerson, setnmPersons] = React.useState(0);
  const [numOfChild, setnmChild] = React.useState(0);

  const [startDay, setstDay] = React.useState("");
  const [endDay, setedDay] = React.useState("");
  const [clientName, setctName] = React.useState("");
  const [fee, setFee] = React.useState(0);
  const [roomCategory, setRMCategory] = React.useState("MamaKet");



  let standPrice = 17 
  let doublePrice = 23 
  let threePrice = 30
  let tripplePrice = 7 
  let total = 0

 

  // #####################################

  const [bookingData, setBooking] = React.useState({
    enterName: "",
    email: "",
    days: "",
    persons: "",
    cheeldren: "",
    favRoom: "",
    dayFrom: new Date().getTime(),
    dayTo: ""
  })

  function GrabbookingData(event) {
    // event.preventDefault();
      setBooking(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
      
  }

  React.useEffect(() => {
    setMail(bookingData.email);
    setctName(bookingData.enterName);
    setrsDays(bookingData.days);
    setstDay(bookingData.dayFrom);
    setedDay(bookingData.dayTo);
    setRoom(bookingData.favRoom);
    setnmPersons(bookingData.persons);
    setnmChild(bookingData.cheeldren)

    // console.log(bookingData.favRoom)

    if(roomType == "singleroom" && numOfPerson < 3) {
      total = 35 * reservedDays
      setFee(total)
      setRMCategory("Single Room")

    } else if (roomType == "singleroom" && numOfPerson > 2) {
      setRMCategory("Single Room")
      total =  0
      setFee(total)

    } 
    
    else if (roomType == "kingroom" && numOfPerson == 1) {
      setRMCategory("King Room")
      total = 36 * reservedDays
      setFee(total)
    } else if (roomType == "kingroom" && numOfPerson == 2) {
      setRMCategory("King Room")
      total = 40 * reservedDays
      setFee(total)
    } else if (roomType == "kingroom" && numOfPerson > 3) {
      setRMCategory("King Room")
      total = 0
      setFee(total)
    }
    else if (roomType == "tripleroom" && numOfPerson == 1) {
      setRMCategory("Triple Room")
      total = 24 * reservedDays
      setFee(total)
    } else if (roomType == "tripleroom" && numOfPerson == 2) {
      setRMCategory("Triple Room")
      total = 27 * reservedDays
      setFee(total)
    } else if (roomType == "tripleroom" && numOfPerson == 3) {
      setRMCategory("Triple Room")
      total = 30 * reservedDays
      setFee(total)
    } else if (roomType == "tripleroom" && numOfPerson > 4) {
      setRMCategory("Triple Room")
      total = 0
      setFee(total)
    }
    
    else if (roomType == "mymuka" && numOfPerson < 7) {
      setRMCategory("My Muka-Entire House")
      total = 90 * reservedDays
      setFee(total)
    } else if (roomType == "mymuka" && numOfPerson > 6) {
      setRMCategory("My Muka-Entire House")
      total = 0
      setFee(total)
    } 
    else if (roomType == "mymoka" && numOfPerson < 4) {
      setRMCategory("My Moka-Entire House")
      total = 70 * reservedDays
      setFee(total)
    } else if (roomType == "mymoka" && numOfPerson > 3) {
      setRMCategory("My Moka-Entire House")
      total = 0
      setFee(total)
    } 
    else {
      setRMCategory("Mama Ket")
      total = 0
      setFee(total)
    }

    if(userMail !== "" && clientName !== "") {
      setNoteP(true)
    } else {
      setNoteP(false)
    }

    // console.log(roomCategory)
  
  });

    // ####################################


    let time = new Date(startDay).getTime();

    let divider = 24 * 60 * 60 * 1000

    let addTime = reservedDays * divider;

    let stopDay = time + addTime

    let Dday = new Date(stopDay).toString().slice(0, 15)

    // console.log(Dday)
    // console.log(bookingData.favRoom)

    React.useEffect(() => {
      setBooking(prevResponse => {
        return {
            ...prevResponse,
            dayTo: Dday

        }
      })
    }, [startDay, userMail, reservedDays, roomType, numOfPerson, clientName] )


  let mail = [userMail, "booking@mamaket.afroeuro.ltd"];
  // let mail = [userMail]

  let nem = "Mama-KET Home Booking"
  let guestName = clientName
  let totalPersons = numOfPerson
  let watoto = numOfChild
  let typeOfroom = roomType
  let totalDays = reservedDays
  let amount = fee
  let firstDay = startDay
  let lastDay = endDay
  let selectedRoom = roomCategory
  let paper1 = `Welcome ${clientName}!!,,,and thank you for choosing Mama KET home. Your have reservation is for ${reservedDays} days as from ${startDay} to ${endDay}. Your total booking fee is $ ${fee}. Enjoy your stay!!`

  function sendMail(event) {
    event.preventDefault();

    axios.get("https://kettwo.afroeuro.ltd/", {
      // axios.get("http://localhost:8081", {

      params: {
        mail,
        nem,
        guestName,
        totalPersons,
        watoto,
        typeOfroom,
        selectedRoom,
        totalDays,
        amount,
        firstDay,
        lastDay,

      },
    })
    .then(() => {
      // console.log("success");
    })
    .catch(() => {
      // console.log("email not sent");
      // console.log("");

    })

    setBooking({
      enterName: "",
      email: "",
      days: "",
      persons: "",
      cheeldren: "",
      favRoom: "",
      dayFrom: new Date().getTime(),
      // dayTo: "",
    })

    toast.success('🦄 Reservation made!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // transition: Bounce,
      });

      // interval = window.setInterval(timer, 1000)
  }
  // console.log(roomCategory)

  // ####################################


  return(
    <main>
      <BacktoTop/>
      <div className='ket-topDiv'>
        <h2 data-aos="zoom-in">Mama Ket Homes Reservation</h2 > 
        <div className='book-unds'></div>
        <p data-aos="zoom-in" className='buk-p'><span className='ket-textBold'>Mama Ket Homes</span> is a welcoming and well-appointed apartment complex located in the serene neighborhood of Lemara, Njiro, Arusha. The property features a selection of accommodations, including a spacious 3-bedroom apartment with three distinct room options: a Single room, a King room, and a Tripple room, offering flexibility and comfort for a variety of guests.</p>
        <p data-aos="zoom-in">Kindly provide reservation details as required below. Thank you!</p>
        
      </div>
       <section className='reserve-sector'>
        
        <aside className='vationInps' data-aos="zoom-in-up">
          <form onSubmit={sendMail}>

          
          
          <div className=''>
            <label className=''>Name</label> <br/>
            <input 
                type="text"
                placeholder="enter name"
                name="enterName"
                className='txt-Inp'
                onChange={GrabbookingData}
                value={bookingData.enterName}
                required
            /> <br/>

            <label>Email</label><br/>
            <input 
                type="email"
                placeholder="enter email"
                name="email"
                className='txt-Inp'
                onChange={GrabbookingData}
                value={bookingData.email}
                required
            /> <br/>


            <label>Number of guest:adults</label><br/>
            <input 
                type="number"
                placeholder="enter guest no"
                name="persons"
                className='txt-Inp'
                min="1"
                onChange={GrabbookingData}
                value={bookingData.persons}
                required
              /> <br/>

            <label>Number of children</label><br/>
            <input 
                type="number"
                placeholder="enter child no"
                name="cheeldren"
                className='txt-Inp'
                min="1"
                onChange={GrabbookingData}
                value={bookingData.cheeldren}
                required
              /> <br/>

            <label>Select room</label><br/>
            <select 
              id='favRoom'
              value={bookingData.favRoom}
              onChange={GrabbookingData}
              name='favRoom'
              className='txt-Inp'>
              <option value="select">--select--</option>
              <option value="singleroom">My Muka Single Room</option>
              <option value="kingroom">My Muka King Room</option>
              <option value="tripleroom"> My Muka Triple Room</option>
              <option value="mymuka">My Muka-entire house</option>
              <option value="mymoka">My Moka-entire house</option>
              
              
            </select><br/>
            
            <label>Number of Nights</label><br/>
            <input 
                type="number"
                placeholder="enter no of nights"
                name="days"
                className='txt-Inp'
                min="1"
                onChange={GrabbookingData}
                value={bookingData.days}
                required
              /> <br/>

            <label>From: </label><br/>
            <input 
                type="date"
                name="dayFrom"
                placeholder="dd-mm-yyyy"
                className='date-inp'
                onChange={GrabbookingData}
                value={bookingData.dayFrom}
                required
            /> <br/>
            <label>To: </label> <br/>
            {/* <input 
                type="date"
                name="dayTo"
                placeholder="dd-mm-yyyy"
                className='date-inp inp-extra'
                onChange={GrabbookingData}
                value={bookingData.dayTo}
            /> <br/> */}

              <input 
                type="text"
                name="dayTo"
                placeholder="dd-mm-yyyy"
                className='date-inp inp-extra'
                // onChange={GrabbookingData}
                value={bookingData.dayTo}
                readonly
              /> <br/>

            <h4>Total Fee: ${fee}</h4>
        
            <button type="submit" className='submitBooking add-width'>Submit</button>
          
          </div>
          </form>
        </aside>

        <nav 
          className='rooms-price'  
          data-aos="fade-up"
          data-aos-duration="3000">
          <div className='rooms-price-div'>
            <h4>Rates per Night</h4>
            <div className='rooms-price-one'>
              <span>Single Room with Balcony View</span><br/>
              <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>35 USD - 1 Guest</p>
                  </div> 
                  
                </nav>
            </div>
            <div className='rooms-price-one'>
              <span>King Room with Balcony View</span><br/>
              <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>36 USD - 1 Guest</p>
                  </div>  

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>40 USD - 2 Guests</p>
                  </div>
                  
                </nav>
            </div>
            <div className='rooms-price-one'>
              <span>Tripple Roomwith Balcony View</span><br/>
              <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>24 USD - 1 Guest</p>
                  </div>  

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>27 USD - 2 Guests</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>30 USD - 3 Guests</p>
                  </div>
                  
                </nav>
              
            </div>

            <div className='rooms-price-one'>
              <span>My Muka-Entire House</span><br/>
              <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>90 USD - 6 Guest</p>
                  </div> 
                  
                </nav>
            </div>

            <div className='rooms-price-one'>
              <span>My Moka-Entire House</span><br/>
              <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>70 USD - 3 Guest</p>
                  </div> 
                  
                </nav>
            </div>
          </div>

          {/* ####################### */}
          
          

          {/* ###################### */}

          <div className='change-houses dec-margin' data-aos="zoom-in">
            <Link to="/Houses/Book-room/all-room" className='change-houses-link'>
            <button><span className='tyyyy'>View Rooms</span> <FaArrowRight className='moveit'/> </button></Link>
          </div>

          <div className='change-houses dec-margin' data-aos="zoom-in">
            <Link to="/Houses/Book-house/all-house" className='change-houses-link'>
            <button><span className='tyyyy'>Go To Houses</span> <FaArrowRight className='moveit'/> </button></Link>
          </div>
          <Link to="/" className='back-home'>HOME</Link>
        </nav>

        
      <Link to="/"><button className='refresh-btnB'>rtty</button></Link>
       </section>

       <Footer/>
       {notifyP ? <ToastContainer />: "" }
    </main>
  )
} 