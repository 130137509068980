import React from 'react';

import { MesaHouseImagesData } from './ImageDatafiles/MesaHouseImages';
import { MamaKetImagesData } from './ImageDatafiles/MamaKetImages';


export default function Gallery() {
  const [imgShower, setimgShower] = React.useState(true)

  const mappedImg = MesaHouseImagesData.map((mesa)=> {
    return (<img src= {mesa.photo} alt='not found' className='gal-photos' data-aos="flip-right"/>)
  })

  const mappedImg2 = MamaKetImagesData.map((mesa2)=> {
    return (<img src= {mesa2.photo} alt='not found' className='gal-photos' data-aos="flip-left"/>)
  })

  // For Mobile

  function SelecKET() {
    setimgShower(true)
  }

  function SelecMESA() {
    setimgShower(false)
  }
  return <section>
    <main className='Gat-computer'>
      <div>
        <p>Mama Ket Homes is designed specifically for families, with ample space and amenities that cater to both adults and children</p>
        <h2>Gallery</h2>
        <div className='g-underscore'></div>
      </div>
      <aside className='ket-mesa'>
        <div className='ket-mesa-buttons'>
          <button className= {`${imgShower ? "KET-btn btn-color": "KET-btn"}`} onClick={SelecKET}>My Muka</button>
          <button className={`${imgShower ? "MESA-btn": "MESA-btn btn-color"}`} onClick={SelecMESA}>My Moka</button>
        </div>
        
        <div className='g-computer-show'>
          <div className={`${imgShower ? "gal-active": "gket-images"}`}>
            {mappedImg}
          </div>
          <div className={`${imgShower ? "gmesa-images": "gal-active"}`}>
            {mappedImg2}
          </div>
        </div>



        {/* For mobile */}
        <div className='g-mobile-show'>
          <div className={`${imgShower ? "gal-active": "gket-images"}`}>
            {mappedImg[17]}
          </div>
          <div className={`${imgShower ? "gmesa-images": "gal-active"}`}>
            {mappedImg2[26]}
          </div>
        </div>
        {/* ##################### */}
        
      </aside>
    </main>
    <main className='Gat-phone'></main>
  </section>
} 