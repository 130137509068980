import React from 'react';
// import Phonenavbar from './phonenavbar';

import { Link } from 'react-router-dom'
import ketLogo from "./Ketlogo.png"
import {FaArrowRight, FaAngleDown, FaAlignRight, FaTimes} from "react-icons/fa"

import BacktoTop from './backtoTop';

export default function Navelements() {

  const [isOpen, setOpen] = React.useState(false)
  
  function Closenav() {
    setOpen(!isOpen)
  }

  return (
    <main className='main-nav'>
      <BacktoTop/>
      <div className='mesa-logo'><img src={ketLogo} alt='not found' className='ket-logo'/></div>
      <ul className='nav-list'>
        <li className='home-li'>Home</li>
        <li>
          <Link to="/AboutPage" className='about-leenk'>About us</Link>
        </li>
        
        <div className='nav-centerDiv'><Link to="/Houses/Book-house/all-house" className='leenk'>Our Houses</Link> <FaAngleDown className='faAngledown'/>
        <div className='sub-menu1'>
            <ul>
              <Link to="/Houses/Book-house/houseFiles/bookKEThouse" className='leenk-sub'><li className='sub-sub-menu1'>My Muka House</li></Link><hr/>
              <Link to="/Houses/Book-house/houseFiles/bookMESAhouse" className='leenk-sub'><li className='sub-sub-menu1'>My Moka House</li></Link><hr/>
              
            </ul>
          </div>
        </div>
        
        <div className='nav-centerDiv'> <Link to="/Houses/Book-room/all-room" className='leenk'>Our Rooms</Link>  <FaAngleDown className='faAngledown'/>
        <div className='sub-menu1'>
            <ul>
              <Link to="/Houses/Book-room/roomFile/stand-room" className='leenk-sub'><li className='sub-sub-menu1'>Single Room with Balcony View </li></Link><hr/>
              <Link to="/Houses/Book-room/roomFile/db-room" className='leenk-sub'><li className='sub-sub-menu1'>King Room with Balcony View</li></Link><hr/>
              <Link to="/Houses/Book-room/roomFile/tp-room" className='leenk-sub'><li className='sub-sub-menu1'>Triple Room with Balcony View</li></Link>
            </ul>
          </div>
        </div>
        <li>Amenities</li>
        <li>
          <Link to="/ContactPage" className='about-leenk'>Contact</Link>
        </li>
        <div className='bookdiv'>
          <Link to="/Houses/Book-room/homePageBooking">
          <button className='book'>BOOK YOUR STAY <FaArrowRight className='book-arrow'/> </button>
          
          </Link>
        </div>
        
        
      </ul>
      <div className='nav-icon'><FaAlignRight onClick={()=> setOpen(true)}/> </div>
      


      {/* PHONE */}

      <div
      className={`${
        isOpen ? 'sidebar-wrapper show' : 'sidebar-wrapper'
      }`}
    >
      <aside className='sidebar'>
        <button className='close-btn' onClick={Closenav}>
          <FaTimes />
        </button>
        <div className='sidebar-links'>

        <div className='phone-bookdiv'>
          <Link to="/">
            <button className='phone-book'>Home </button>
          </Link>   
        </div>

        <div className='phone-bookdiv'>
          <Link to="/AboutPage">
            <button className='phone-book'>About Us</button>
          </Link>   
        </div>

        <div className='phone-bookdiv'>
          <Link to="/Houses/Book-house/all-house">
            <button className='phone-book'>Our Houses</button>
          </Link>   
        </div>

        <div className='phone-bookdiv'>
          <Link to="/Houses/Book-room/all-room">
            <button className='phone-book'>Our Rooms</button>
          </Link>   
        </div>

        <div className='phone-bookdiv'>
          <Link to="/ContactPage">
            <button className='phone-book'>Contact Us</button>
          </Link>   
        </div>

        <div className='phone-bookdiv'>
          <Link to="/Houses/Book-room/homePageBooking">
            <button className='phone-book'>BOOK YOUR STAY <FaArrowRight className='phone-book-arrow'/> </button>
          </Link>
              
        </div>

        <ul className='phone-ul'>
            {/* <li className='trial'>Home</li> */}
            {/* <li>
            <Link className='side-pnlink' to="/AboutPage">About Us</Link>
            
            </li> */}
            {/* ##################################### */}

            {/* <div className='phone-subnav'><Link className='side-pnlink' to="/Houses/Book-house/all-house">Our Houses</Link> 
            
            <FaAngleDown className='faAngledown'/>
              <div className='phone-sub-menu1'>
                <ul>
                  <Link to="/Houses/Book-house/houseFiles/bookKEThouse"><li className='phone-sub-sub-menu1'>My Muka House</li></Link> <hr/>
                  <Link to="/Houses/Book-house/houseFiles/bookMESAhouse"><li className='phone-sub-sub-menu1'>My Muka House</li></Link><hr/>
                  
                </ul>
              </div>
              
            </div> */}

            {/* $####################################### */}
            
            {/* <div className='phone-subnav'><Link className='side-pnlink' to="/Houses/Book-room/all-room">Our Rooms</Link> <FaAngleDown className='faAngledown'/>
              <div className='phone-sub-menu1'>
                <ul>
                  <Link to="/Houses/Book-room/roomFile/stand-room"><li className='phone-sub-sub-menu1'>Single Room with Balcony View</li></Link> <hr/>
                  <Link to="/Houses/Book-room/roomFile/db-room"><li className='phone-sub-sub-menu1'>King Room with Balcony View</li></Link><hr/>
                  <Link to="/Houses/Book-room/roomFile/tp-room"><li className='phone-sub-sub-menu1'>Triple Room with Balcony View</li></Link><hr/>
                </ul>
              </div>
            </div> */}
            {/* <li>Amenities</li> */}

            {/* <li>
            <Link className='side-pnlink' to="/ContactPage">Contact Us</Link>
            
            </li> */}

            
            
            
          </ul>
        </div>
      </aside>
    </div>

    </main>
  )
}