

import photo1 from "../MesaHouseImages/inside1.jpg"
import photo2 from "../MesaHouseImages/inside2.jpg"
import photo3 from "../MesaHouseImages/inside3.jpg"
import photo4 from "../MesaHouseImages/inside4.jpg"
import photo5 from "../MesaHouseImages/inside5.jpg"
import photo6 from "../MesaHouseImages/inside6.jpg"
import photo7 from "../MesaHouseImages/inside7.jpg"
import photo8 from "../MesaHouseImages/inside8.jpg"
import photo9 from "../MesaHouseImages/inside9.jpg"
import photo10 from "../MesaHouseImages/inside10.jpg"
import photo11 from "../MesaHouseImages/inside11.jpg"
import photo12 from "../MesaHouseImages/inside12.jpg"
import photo13 from "../MesaHouseImages/inside13.jpg"
import photo14 from "../MesaHouseImages/inside14.jpg"
import photo15 from "../MesaHouseImages/inside15.jpg"
import photo16 from "../MesaHouseImages/inside16.jpg"


import Tphoto1 from "../MesaHouseImages/outinside1.jpg"
import Tphoto2 from "../MesaHouseImages/outinside2.jpg"
import Tphoto3 from "../MesaHouseImages/outinside3.jpg"
import Tphoto4 from "../MesaHouseImages/outinside4.jpg"
import Tphoto5 from "../MesaHouseImages/outinside5.jpg"
import Tphoto6 from "../MesaHouseImages/outinside6.jpg"
import Tphoto7 from "../MesaHouseImages/outinside7.jpg"
import Tphoto8 from "../MesaHouseImages/outinside8.jpg"


export const MesaHouseImagesData = [
  {
    id: 1,
    photo: photo1
  },
  {
    id: 2,
    photo: photo2
  },
  {
    id: 3,
    photo: photo3
  },
  {
    id: 4,
    photo: photo4
  },
  {
    id: 5,
    photo: photo5
  },
  {
    id: 6,
    photo: photo6
  },
  {
    id: 7,
    photo: photo7
  },
  {
    id: 8,
    photo: photo8
  },
  {
    id: 9,
    photo: photo9
  },
  {
    id: 10,
    photo: photo10
  },
  {
    id: 11,
    photo: photo11
  },
  {
    id: 12,
    photo: photo12
  },
  {
    id: 13,
    photo: photo13
  },
  {
    id: 14,
    photo: photo14
  },
  {
    id: 15,
    photo: photo15
  },
  {
    id: 16,
    photo: photo16
  },
  {
    id: "T1",
    photo: Tphoto1
  },
  {
    id: "T2",
    photo: Tphoto2
  },
  {
    id: "T3",
    photo: Tphoto3
  },
  {
    id: "T4",
    photo: Tphoto4
  },
  {
    id: "T5",
    photo: Tphoto5
  },
  {
    id: "T6",
    photo: Tphoto6
  },
  {
    id: "T7",
    photo: Tphoto7
  },
  {
    id: "T8",
    photo: Tphoto8 
  },

]
