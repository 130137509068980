import React from 'react';

import {FaLinkedin, FaTwitter, FaPhone, FaEnvelope, FaInstagram, FaCopyright} from "react-icons/fa";
import { Link } from 'react-router-dom'



export default function Footer() {
  let currentYear = new Date().getFullYear()
  return (
    <main className='footer' >
      <div className='footer-div1'>
        <span><FaPhone/></span>
        <span><FaEnvelope/></span>
        <span><FaTwitter/></span>
        <span><FaLinkedin/></span>
        <span><FaInstagram/></span>
      </div>
      <div className='footer-div2'>
        <FaCopyright/>
        <span className='C-year'>{currentYear}</span>
        <span >AllRights Reserved: </span>
        <Link to="https://afroeuro.ltd/"> 
          <span className='nick'>mamakethomes</span>
        </Link>
      </div>

    </main>
  )
}