import React from 'react';
import axios from 'axios';

import Blacknavbar from '../../../blackNavbar';
import BacktoTop from '../../../Navbar/backtoTop';
import Booking from '../../../Navbar/bookForm';

import { useGlobalContext } from '../../../context';
import {FaTimes} from "react-icons/fa";
import { Link } from 'react-router-dom';


import {FaSquareFull, FaAngleRight} from "react-icons/fa"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RetanToHouses from '../../returnToHouses';
import Footer from '../../../footer';

import ketLogo from "../../../Houses/Ketlogo.png"
import ket1 from '../../../Elements/Gallery/MamaKetImages/insideK1.jpg';
import ket2 from '../../../Elements/Gallery/MamaKetImages/insideK2.jpg';
import ket3 from '../../../Elements/Gallery/MamaKetImages/insideK3.jpg';
import ket4 from '../../../Elements/Gallery/MamaKetImages/insideK4.jpg';
import ket5 from '../../../Elements/Gallery/MamaKetImages/insideK5.jpg';
import ket6 from '../../../Elements/Gallery/MamaKetImages/insideK6.jpg';
import ket7 from '../../../Elements/Gallery/MamaKetImages/insideK7.jpg';
import ket8 from '../../../Elements/Gallery/MamaKetImages/insideK8.jpg';
import ket9 from '../../../Elements/Gallery/MamaKetImages/insideK9.jpg';
import ket10 from '../../../Elements/Gallery/MamaKetImages/insideK10.jpg';
import ket11 from '../../../Elements/Gallery/MamaKetImages/insideK11.jpg';
import ket12 from '../../../Elements/Gallery/MamaKetImages/insideK12.jpg';
import ket13 from '../../../Elements/Gallery/MamaKetImages/insideK13.jpg';
import ketK1 from '../../../Elements/Gallery/MamaKetImages/outsideK1.jpg';
import ketK2 from '../../../Elements/Gallery/MamaKetImages/outsideK2.jpg';
import ketK3 from '../../../Elements/Gallery/MamaKetImages/outsideK3.jpg';
import ketK4 from '../../../Elements/Gallery/MamaKetImages/outsideK4.jpg';
import ketK5 from '../../../Elements/Gallery/MamaKetImages/outsideK5.jpg';
import ketK6 from '../../../Elements/Gallery/MamaKetImages/outsideK6.jpg';
import ketK7 from '../../../Elements/Gallery/MamaKetImages/outsideK7.jpg';
import ketK8 from '../../../Elements/Gallery/MamaKetImages/outsideK8.jpg';
import ketK9 from '../../../Elements/Gallery/MamaKetImages/outsideK9.jpg';
import ketK10 from '../../../Elements/Gallery/MamaKetImages/outsideK10.jpg';
import ketK11 from '../../../Elements/Gallery/MamaKetImages/outsideK11.jpg';
import ketK12 from '../../../Elements/Gallery/MamaKetImages/outsideK12.jpg';
import ketK13 from '../../../Elements/Gallery/MamaKetImages/outsideK13.jpg';
import ketK14 from '../../../Elements/Gallery/MamaKetImages/outsideK14.jpg';
import ketK15 from '../../../Elements/Gallery/MamaKetImages/outsideK15.jpg';
import ketK16 from '../../../Elements/Gallery/MamaKetImages/outsideK16.jpg';
import ketK17 from '../../../Elements/Gallery/MamaKetImages/outsideK17.jpg';
import ketK18 from '../../../Elements/Gallery/MamaKetImages/outsideK18.jpg';
import ketK19 from '../../../Elements/Gallery/MamaKetImages/outsideK19.jpg';
import ketK20 from '../../../Elements/Gallery/MamaKetImages/outsideK20.jpg';
import ketK21 from '../../../Elements/Gallery/MamaKetImages/outsideK21.jpg';
import ketK22 from '../../../Elements/Gallery/MamaKetImages/outsideK22.jpg';
import ketK23 from '../../../Elements/Gallery/MamaKetImages/outsideK23.jpg';



export default function BookKET() {
  const {makeOder, setOder} = useGlobalContext();

  const [userMail, setMail] = React.useState("");
  const [reservedDays, setrsDays] = React.useState(0);
  const [numOfRooms, setnmRooms] = React.useState(0);
  const [numOfPerson, setnmPersons] = React.useState(0);
  const [startDay, setstDay] = React.useState("");
  const [endDay, setedDay] = React.useState("");
  const [clientName, setctName] = React.useState("");
  const [fee, setFee] = React.useState(0);

  let standPrice = 17 
  let doublePrice = 23 
  let threePrice = 30
  let tripplePrice = 7 
  let total = 0

  // function PopOrder() {
  //   setOder(true)
  // }

  // #####################################

  const [bookingData, setBooking] = React.useState({
    enterName: "",
    email: "",
    days: "",
    persons: "",
    numOFroom: "",
    dayFrom: "",
    dayTo: ""
  })

  function GrabbookingData(event) {
    // event.preventDefault();
      setBooking(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
      
      
  }
  React.useEffect(() => {
    setMail(bookingData.email);
    setctName(bookingData.enterName);
    setrsDays(bookingData.days);
    setstDay(bookingData.dayFrom);
    setedDay(bookingData.dayTo);
    setnmRooms(bookingData.numOFroom);
    setnmPersons(bookingData.persons)


  

      if(numOfRooms == 1 && numOfPerson < 3) {
        console.log("single")
        total = standPrice * reservedDays
        setFee(total)
      

      } else if(numOfRooms == 1 && numOfPerson > 2) {
        console.log("single")
        total = tripplePrice * reservedDays * numOfPerson;
        setFee(total)
      }
      else if(numOfRooms == 2 && numOfPerson < 3) {
        console.log("double")
        total = doublePrice * reservedDays;
        setFee(total)
      }
      else if(numOfRooms == 2 && numOfPerson > 2) {
        console.log("double")
        total = tripplePrice * reservedDays * numOfPerson;
        setFee(total)
      }
      else if(numOfRooms == 3 && numOfPerson < 4 ) {
        console.log("tripple")
        total = threePrice * reservedDays;
        setFee(total)

      }
  
      else if(numOfRooms > 3 ) {
        console.log("tripple")
        total = 12 * numOfRooms * reservedDays;
        setFee(total)

      } else {
        console.log("not set")
        total = 0;
        setFee(total)

      }
    });

  // ####################################
  let mail = ["nicksonnyongesa73@gmail.com", userMail]
  let nem = "Mama-KET Home Booking"
  let guestName = clientName
  let totalPersons = numOfPerson
  let totalRooms = numOfRooms
  let totalDays = reservedDays
  let amount = fee
  let firstDay = startDay
  let lastDay = endDay
  let paper1 = `Welcome ${clientName}!!,,,and thank you for choosing Mama KET home. Your have reservation is for ${reservedDays} days as from ${startDay} to ${endDay}. Your total booking fee is $ ${fee}. Enjoy your stay!!`

  function sendMail() {
    axios.get("http://localhost:8081/", {
      params: {
        mail,
        nem,
        guestName,
        totalPersons,
        totalRooms,
        totalDays,
        amount,
        firstDay,
        lastDay,

      },
    })
    .then(() => {
      console.log("success");
    })
    .catch(() => {
      console.log("email not sent");
    })

    toast.success('🦄 Reservation made!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // transition: Bounce,
      });
  }

  // ####################################

  function CloseOdaform() {
    setOder(false)
  }

  // ####################################
  
  return (
    <main>
      <BacktoTop/>
      <Blacknavbar/>

      <section className='hse-mainSection'>
        <aside className='hse-background-ket'>
          <RetanToHouses/>

          <div className='hse-mesaH'>
            <h1 data-aos="zoom-in">My Muka House</h1>
            <p data-aos="zoom-in" className='mmmo-white'>Where Comfort Meets Culture at Mama Ket Homes.</p>
          </div>

        </aside>

        <aside className='hse-Aside-div'>
          <div className='hse-imgside'>
            <img src={ket6} 
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className='hse-img1'/>
            <h3 data-aos="zoom-in" className='hse-ffp'>My Muka House: Experience A real lovely stay, a real lovely gem</h3>
            <p>My Muka Arusha offers a home-like feeling in a peaceful setting surrounded by nature, just 10 minutes by car or 30 minutes by foot from the city center of Arusha. Selected rooms feature a balcony, and all units include free WiFi and a private bathroom. We offer additional services including airport transfers "available upon payment", and safari arrangements. Whether you're here to relax, feel at home, or explore Arusha and the surrounding areas, My Muka Arusha is the perfect retreat.</p>
            <span>KARIBU!</span>
            <p>Each morning, we serve a fresh, hearty breakfast made from locally sourced ingredients to start your day right. Our breakfast options range from fresh fruits and pastries to traditional hot meals, prepared with care to satisfy all tastes. It's the perfect way to fuel up before a day of relaxation or adventure.</p>

            

            
            <div className='hse-otherImg'>
              <img src={ket1} alt='not found' data-aos="flip-up"/>
              <img src={ket2} alt='not found' data-aos="flip-up"/>
              <img src={ket3} alt='not found' data-aos="flip-up"/>
              <img src={ket4} alt='not found' data-aos="flip-up"/>
              <img src={ket5} alt='not found' data-aos="flip-up"/>
              <img src={ket6} alt='not found' data-aos="flip-up"/>
              <img src={ket7} alt='not found' data-aos="flip-up"/>
              <img src={ket8} alt='not found' data-aos="flip-up"/>
              <img src={ket9} alt='not found' data-aos="flip-up"/>
              <img src={ket10} alt='not found' data-aos="flip-up"/>
              <img src={ket11} alt='not found' data-aos="flip-up"/>
              <img src={ket12} alt='not found' data-aos="flip-up"/>
              <img src={ket13} alt='not found' data-aos="flip-up"/>
              <img src={ketK1} alt='not found' data-aos="flip-up"/>
              <img src={ketK2} alt='not found' data-aos="flip-up"/>
              <img src={ketK3} alt='not found' data-aos="flip-up"/>
              <img src={ketK4} alt='not found' data-aos="flip-up"/>
              <img src={ketK5} alt='not found' data-aos="flip-up"/>
              <img src={ketK6} alt='not found' data-aos="flip-up"/>
              <img src={ketK7} alt='not found' data-aos="flip-up"/>
              <img src={ketK8} alt='not found' data-aos="flip-up"/>
              <img src={ketK9} alt='not found' data-aos="flip-up"/>
              <img src={ketK10} alt='not found' data-aos="flip-up"/>
              <img src={ketK11} alt='not found' data-aos="flip-up"/>
              <img src={ketK12} alt='not found' data-aos="flip-up"/>
              <img src={ketK13} alt='not found' data-aos="flip-up"/>
              <img src={ketK14} alt='not found' data-aos="flip-up"/>
              <img src={ketK15} alt='not found' data-aos="flip-up"/>
              <img src={ketK16} alt='not found' data-aos="flip-up"/>
              <img src={ketK17} alt='not found' data-aos="flip-up"/>
              <img src={ketK18} alt='not found' data-aos="flip-up"/>
              <img src={ketK19} alt='not found' data-aos="flip-up"/>
              <img src={ketK20} alt='not found' data-aos="flip-up"/>
              <img src={ketK21} alt='not found' data-aos="flip-up"/>
              <img src={ketK22} alt='not found' data-aos="flip-up"/>
              <img src={ketK23} alt='not found' data-aos="flip-up"/>



            </div>
            <nav className='hse-msPoints'>
              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Check in from 12.00 Check out 11.00am</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Complimentary WiFi throughout the House</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Laundry service</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Housekeeping Services:</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Spacious Living and Dining Areas</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Barbeque Facilities</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Bathroom with hot and cold shower, and flush toilets</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Coffee/tea making facility</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Secure Parking</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Family-Friendly Environment</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Fully Equipped Kitchen</p>
              </div>
            </nav>
          </div>
          <section className='booside-sec'>
            <div className='hse-bookide'>
              <div className='hse-bookide-div1'>
                <aside>
                  <div className='bk-underscore'></div>
                  <div>
                    <h4>Between $80 to $100 / Night</h4>
                    <h4>6 Guests</h4>
                    

                  </div>
                </aside>
                <p><span className='cm-bdroom'>Comfortable Bedrooms:</span>Choose from Single, King, Triple rooms or Entire House, all featuring comfortable beds and ample storage space.</p>

                <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>6 Guests</p>
                  </div>  

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>3 Bedrooms</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>1 Living room</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>Garden</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>Balcony</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    
                    <p className='ppwwp'>Breakfast</p>
                  </div>
                  
                </nav>
                <Link to="/Houses/Book-room/homePageBooking" className='book-btn'>BOOK</Link>
              </div>
              
              <div className='hse-bookide-div2'>
                <h2>Reservation</h2>
                <p>You can book online by clicking the <span>book-button</span> For further enquiries and availability please visit our  contact page. It’s designed to help our valued guests find their ideal Mama Ket Homes experience.</p>
                <button className='cu-btn'>Contact us</button>
              </div>
            </div>
          </section>
          {/* <div className='hse-bookide'></div> */}

        </aside>
        

      </section>

      {/* ###################### */}

      <div className= {`${makeOder ? "OrderForm showForm": "OrderForm"}`}>
      
      <aside className='sidebar-Aside '>
      <button className='close-btn' onClick={CloseOdaform}>
          <FaTimes />
        </button>
        <div className='res-tittle'>
          <h4>Mama Ket House Reservation</h4><hr/>
        </div>
        <div className='sidebar-Aside-div'>
          <label className='topN'>Name</label> <br/>
          <input 
              type="text"
              placeholder="Enter Name"
              name="enterName"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.enterName}
          /> <br/>

          <label>Email</label><br/>
          <input 
              type="email"
              placeholder="Enter Email"
              name="email"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.email}
          /> <br/>

          <label>Number of Persons:</label>
          <input 
              type="number"
              placeholder="enter no"
              name="persons"
              className='txt-Inp-ket'
              min="1"
              onChange={GrabbookingData}
              value={bookingData.persons}
            /> <br/>

          <label>Number of Rooms:</label>
          <input 
              type="number"
              placeholder="enter no"
              name="numOFroom"
              min="1"
              className='txt-Inp-ket'
              onChange={GrabbookingData}
              value={bookingData.numOFroom}
            /> <br/>


          <label>Number of Nights:</label>
          <input 
              type="number"
              placeholder="Enter no"
              name="days"
              className='txt-Inp-ket'
              onChange={GrabbookingData}
              value={bookingData.days}
            /> <br/>

          <label>From: </label>
          <input 
              type="date"
              name="dayFrom"
              placeholder="dd-mm-yyyy"
              className='inp-space'
              onChange={GrabbookingData}
              value={bookingData.dayFrom}
          /> <br className='seperate-inp'/>
          <label>To: </label> 
          <input 
              type="date"
              name="dayTo"
              placeholder="dd-mm-yyyy"
              onChange={GrabbookingData}
              value={bookingData.dayTo}
          /> <br/>

          <h4>Total Fee: ${fee}</h4>
      
          <button type="submit" onClick={sendMail} className='submitBooking'>Submit</button>
          <hr/>
        </div>

      </aside>
    </div>

      {/* ###################### */}

    <Footer/>
    <ToastContainer/>
    </main>
  )
}