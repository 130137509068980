import React from 'react';

import {FaWifi, FaParking, FaRestroom} from "react-icons/fa"
import { MdBalcony } from "react-icons/md";
import { FaKitchenSet } from "react-icons/fa6";

import interior1 from "./MamaketPhotos/interior1.jpg"
import interior2 from "./MamaketPhotos/interior2.jpg"
import green1 from "./MamaketPhotos/green1.jpg"
import green2 from "./MamaketPhotos/green2.jpg"

export default function Mamaket() {
  return (
    <main>
      <div className='ket-description'>
        <div className='ket-text'>
          <div className='your-cozy'>
            <h5 data-aos="zoom-in">Your Cozy Retreat in Lemara, Njiro, Arusha</h5>
            <h1 data-aos="zoom-in">My Muka Homes!</h1>
          </div>
          <div className='your-cozy-text'>
            <div className='cozy-underscore'></div>
            <p data-aos="fade-up"><span>My Muka Homes </span>offers a home-like feeling in a peaceful setting surrounded by nature, just 10 minutes by car or 30 minutes by foot from the city center of Arusha. Selected rooms feature a balcony, and all units include free WiFi and a private bathroom. We offer additional services including airport transfers "available upon payment", and safari arrangements. Whether you're here to relax, feel at home, or explore Arusha and the surrounding areas, My Muka Arusha is the perfect retreat.<span> KARIBU! </span>Each morning, we serve a fresh, hearty breakfast made from locally sourced ingredients to start your day right. Our breakfast options range from fresh fruits and pastries to traditional hot meals, prepared with care to satisfy all tastes. It's the perfect way to fuel up before a day of relaxation or adventure.</p>
          </div>
        </div>

        <div className='ket-images'>
          <div className='ket-interior'>
            <img src={interior1} alt='not found' className='ket-photo-short space-top'/>
            <img src={interior2} alt='not found' className='ket-photo-long'/>
          </div>
          <div className='ket-green'>
          <img src={green1} alt='not found' className='ket-photo-long'/>
          <img src={green2} alt='not found' className='ket-photo-short space-bottom'/>

          </div>

        </div>
      </div>
      <section className='house-features'>
        <span className='ft-span'>Features</span>
        <div className='house-div'>
          <div className='house-text' data-aos="zoom-in">
            <span className='house-Fa'><MdBalcony/></span>
            <span className='sp-text'>Balcony</span>
          </div>
          <div className='house-text' data-aos="zoom-in">
            <span className='house-Fa'><FaKitchenSet/></span>
            <span className='sp-text'>Kitchen</span>
          </div>
          <div className='house-text' data-aos="zoom-in">
            <span className='house-Fa'><FaWifi/></span>
            <span className='sp-text'>Free Wifi</span>
          </div>
          <div className='house-text' data-aos="zoom-in">
            <span className='house-Fa'><FaParking/></span>
            <span className='sp-text'>Free parking</span>
          </div>
          <div className='house-text' data-aos="zoom-in">
            <span className='house-Fa'><FaRestroom/></span>
            <span className='sp-text'>Family rooms</span>
          </div>
        </div>
      </section>
    </main>
  )
} 