import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom'


import Home from './home';
import Bookmesa from './Houses/Book-house/houseFiles/bookMESAhouse';
import Allhouse from './Houses/Book-house/all-house';
import BookKET from './Houses/Book-house/houseFiles/bookKEThouse';
import Allrooms from './Houses/Book-room/all-room';
import Standardroom from './Houses/Book-room/roomFile/stand-room';
import Doubleroom from './Houses/Book-room/roomFile/db-room';
import Trippleroom from './Houses/Book-room/roomFile/tp-room';
import About from './AboutPage';
import ContactPage from './ContactPage';
import QuickBooking from './Houses/Book-room/homePageBooking';

export default function App() {
  return (
    <main>
      
      <BrowserRouter>
    
        <Routes>
          < Route path="/" element={<Home/>}/>
          < Route path="/Houses/Book-house/all-house" element={<Allhouse/>}/>
          < Route path="/Houses/Book-house/houseFiles/bookMESAhouse" element={<Bookmesa/>}/>
          < Route path="/Houses/Book-house/houseFiles/bookKEThouse" element={<BookKET/>}/>
          < Route path="/Houses/Book-room/all-room" element={<Allrooms/>}/>
          < Route path="/Houses/Book-room/roomFile/stand-room" element={<Standardroom/>}/>
          < Route path="/Houses/Book-room/roomFile/db-room" element={<Doubleroom/>}/>
          < Route path="/Houses/Book-room/roomFile/tp-room" element={<Trippleroom/>}/>
          < Route path="/AboutPage" element={<About/>}/>
          < Route path="/ContactPage" element={<ContactPage/>}/>
          < Route path="/Houses/Book-room/homePageBooking" element={<QuickBooking/>}/>




          

         
        </Routes>
      </BrowserRouter>
      
    </main>
  )
} 