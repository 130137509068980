import React from 'react';

import BacktoTop from './Navbar/backtoTop';
import Blacknavbar from './blackNavbar';
import Footer from './footer';

// import backImg from "./Background-images/snbg.png"
import ketLogo from "./Navbar/Ketlogo.png"

import { Link } from 'react-router-dom'
import {FaClock,FaPhone, FaEnvelope, FaMapMarker} from "react-icons/fa"

import axios from 'axios';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ContactPage() {
  const [notifyCP, setNoteCP] = React.useState(true)

  // let [secvalueCP, setSecCP] = React.useState(0)

  // let seconds = 0;
  

  // let interval = null
  // let currentState = "stopped"

  // function timer() {
  //   seconds += 1
  //   // if (seconds / 60 === 1) {
  //   //     seconds = 0
  //   // }
  //   setSecCP(seconds)
    

  // }
  // console.log(secvalue)

  // if(secvalueCP === 6) {
  //   document.querySelector(".refresh-btnC").click()
  // }


  const [clientName, setclientName] = React.useState("");
  const [clientEmail, setclientMail] = React.useState("");
  const [enquiry, setEnquiry] = React.useState("");
  const [thecomment, setctComment] = React.useState("");

  const [responseData, setResponse] = React.useState({
    enterName: "",
    email: "",
    messageType: "",
    comment: "",

  })

  function GrabInputs(event) {
      setResponse(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
  }
  

  React.useEffect(()=> {
    setclientName(responseData.enterName)
    setclientMail(responseData.email);
    setEnquiry(responseData.messageType);
    setctComment(responseData.comment)

    if(clientEmail !== "" && clientName !== "") {
      setNoteCP(true)
    } else {
      setNoteCP(false)
    }
  
  })

  // let mail = ["nicksonnyongesa73@gmail.com", "festasotieno97@gmail.com"]
  let mail = ["enquire@mamaket.afroeuro.ltd"]

  let nem = "Mama-KET Home"

  function sendMail(event) {
  
    event.preventDefault();
    axios.get("https://kettwo.afroeuro.ltd/clientEnquiry", {
    // axios.get("http://localhost:8081/clientEnquiry", {

      params: {
        mail,
        nem,
        clientName,
        clientEmail,
        enquiry,
        thecomment,

      },
    })
    .then(() => {
      // console.log("success");
    })
    .catch(() => {
      // console.log("email not sent");
      // console.log("");
    })

    setResponse({
      enterName: "",
      email: "",
      messageType: "--select--",
      comment: "",
    })

    toast.success('🦄 Message Sent!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // transition: Bounce,
      });

  }

  return (
    <main>
      <BacktoTop/>
      <Blacknavbar/>
      <div className='enqContainer'>
          <aside>
            <div className='return-om'>
              <Link to="/">
                <img src={ketLogo} className='about-logo' data-aos="flip-left"/>
              </Link>
              
              <div>
                <Link className='om-link' to={"/"}>
                  Home
                </Link>

                <Link className='om-link' to={"/Houses/Book-room/homePageBooking"}>
                  Book Room
                </Link>
              </div>
              
            </div>

            <main className='endForm'>
              <div className='endForm-text' data-aos="zoom-out-down">
                <h1 className='con-h1'>Contact Info</h1>
                {/* <div className='c-underscore'></div> */}
                <p className='for-distance faded-white'>Mama Ket Homes is located in the serene neighborhood of Lemara, Njiro, Arusha. Just 10 minutes by car or 30 minutes by foot from the city center of Arusha.</p>
                <span className='for-booking faded-white'>For booking enquiries, please send us a message or reach out to our reservations team in Arusha, Tanzania.</span>
                <div className='sub-contact'>
                  <div className='sub-sub-contact-conPage'>
                    <FaClock className='sub-contact-fa'/>
                    <div>
                      <h4 className='faded-white'>Working Hours</h4>
                      <span className='faded-white'>24/7 Hours</span>
                    </div>
                  </div>
                  <div className='sub-sub-contact-conPage'>
                  <FaMapMarker className='sub-contact-fa'/>
                    <div>
                      <h4 className='faded-white'>Our Office Address</h4>
                      <span className='faded-white'>Lemara Road, Njiro Arusha Tanzania</span>
                    </div>
                  </div>
                  <div className='sub-sub-contact-conPage'>
                  <FaPhone className='sub-contact-fa'/>
                    <div>
                      <h4 className='faded-white'>Phone Number</h4>
                      <span className='faded-white'>+255 717 112 802</span>
                    </div>
                  </div>
                  <div className='sub-sub-contact-conPage'>
                  <FaEnvelope className='sub-contact-fa'/>
                    <div>
                      <h4 className='faded-white'>Email</h4>
                      <span className='faded-white'>karibu@mamakethomes.co.tz</span>
                    </div>
                  </div>

                </div>
              </div>

              {/* $$$$$ FORM */}
              

              <div className='endForm-inp' >
                <form onSubmit={sendMail}>
                <aside className="input-div" 
                data-aos="fade-up"
                data-aos-duration="3000"
                >
                  <label className='faded-white'>Name</label>
                  <input 
                      type="text"
                      placeholder="Enter Name"
                      name="enterName"
                      onChange={GrabInputs}
                      value={responseData.enterName}
                      required
                  />

                <label htmlFor='message' className='faded-white'>Subject</label>
                  <select className='message'
                  id='messageType'
                    value={responseData.message}
                    name="messageType"
                    onChange={GrabInputs}
                  >
                    <option value="accomodations">--select--</option>
                    <option value="accomodations">Accomodations</option>
                    <option value="feedback">Feedback/Suggestions</option>
                    <option value="enquiry">General-Enquiry</option>
                    <option value="Other">Other</option>

                  </select>
                  <label className='faded-white'>Email</label>
                  <input 
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      onChange={GrabInputs}
                      value={responseData.email}
                      required
                  />

                  <label className='faded-white'>Message</label>
                  <textarea 
                      placeholder="Your Message"
                      name="comment"
                      onChange={GrabInputs}
                      value={responseData.comment}
                      rows="5"
                      required

                  />
                  
                    <button type="submit" className="formBtn">Send Message</button>
                  

                </aside>

                </form>
              </div>
              

            </main>
          </aside>
        </div>
      {/* <Link to="/"><button className='refresh-btnC'>rtty</button></Link> */}
       
      <Footer/>
      {notifyCP ? <ToastContainer />: "" }
    </main>
  )
}