import React from 'react';

import {FaStar, FaQuoteLeft, FaArrowLeft, FaArrowRight} from "react-icons/fa"

import { MdPerson } from "react-icons/md";



export default function Bestexpi() {


  const [imgNumberB, setImgnumberB] = React.useState(1)

  const selectDiv = document.querySelectorAll(".clients-sliding-div")

  function NextImg() {
    setImgnumberB(prevImg => prevImg + 1) 

  }

  function PrevImg() {
    setImgnumberB(prevImg=> prevImg - 1)
  }


  React.useEffect(()=> {
    if(imgNumberB > selectDiv.length) {
      setImgnumberB(prevImg=> 1)
    } else if (imgNumberB === 0) {
      setImgnumberB(prevImg=> selectDiv.length)
    }
  }, [imgNumberB])


  const trans1 = {
    // transform: `translateX(-${(imgNumberB - 1) * 500}px)`
    transform: `translateX(-${(imgNumberB - 1) * 100}%)`
  }
  
  return <main className='best-experience'>
    <h2>Enjoy The Best Experience With Us</h2>
    <section className='clients-remarks'>
    <div className='clients-sliding-div' style={trans1}>
        <div className='cs-div1'>
          <div className='quote-div' data-aos="fade-up">
            <span className=''><FaQuoteLeft/></span>
            <h4 data-aos="fade-up" className='faded-white'>Spacious, well-equipped apartment in central Arusha!</h4>
          </div>
          <div className='clients-star' data-aos="fade-up">
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
          </div>
          <p data-aos="zoom-in" className='faded-white'>We had such a fantastic stay at this spacious family apartment in the center of Arusha. The apartment was incredibly well-equipped with everything we needed for a comfortable stay. The location was ideal, with easy access to all the main attractions and amenities in the city. The apartment was clean and had plenty of room for our entire family to relax and enjoy our time together. We felt very much at home here and would highly recommend this place to anyone visiting Arusha. We will definitely return if we come back to Arusha in the future!</p>
          <span data-aos="fade-up">22 August 2024</span>
        </div>
        <div className='clients-name' data-aos="fade-up">
          <MdPerson className='clients-img'/>
          
          <div>
            <h4>Pervin</h4>
            <span className='faded-white'>Germany</span>
          </div>
        </div>
        
      </div>

      {/* ########### */}

      <div className='clients-sliding-div' style={trans1}>
        <div className='cs-div1'>
          <div className='quote-div' data-aos="fade-up">
            <span><FaQuoteLeft /></span>
            <h4 className='faded-white'>A True Home Away From Home</h4>
          </div>
          <div className='clients-star' data-aos="fade-up">
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
          </div>
          <p data-aos="zoom-in" className='faded-white'> Mama Ket Homes was the perfect choice for our family trip to Arusha! We stayed in the My Moka house, which had two comfortable bedrooms, a well-equipped kitchen and a spacious living/dining area. The entire apartment was clean, fully furnished, and felt just like home. The peaceful location in Lemara, Njiro, was ideal, and the staff was incredibly friendly and helpful. With free Wi-Fi, secure parking, and regular housekeeping, it offered everything we needed for a relaxing stay. Highly recommend for families or anyone looking for a comfortable, convenient place to stay in Arusha</p>
          <span>01 August 2024</span>
        </div>
        <div className='clients-name' data-aos="fade-up">
          <MdPerson className='clients-img'/>
          <div>
            <h4>Tom Jones</h4>
            <span className='faded-white'>USA</span>
          </div>
        </div>
        
      </div>


        {/* %%%%%%%%%%%%%%%%%%%%%%% */}
    </section>
    <aside className='be-arrows'>
        <FaArrowLeft className='fa-arrow' onClick={PrevImg}/>
        <FaArrowRight className='fa-arrow' onClick={NextImg}/>

      </aside>
    
  </main>
} 