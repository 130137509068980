import React from 'react';

import {FaAngleRight} from "react-icons/fa";
import { FaRegSquare } from "react-icons/fa";
import { Link } from 'react-router-dom'

import banana from "./banana.jpg"

export default function Amenities() {
  return <main className='amenities'>
    <img src={banana} data-aos="fade-up"
     data-aos-duration="3000"/>
    <div className='amenities-text'>
      <h1 className='faded-white'>Our Amenities</h1>
      <p className='faded-white'>Mama Ket Homes offers a range of amenities designed to enhance the comfort and convenience of your stay. My Muka apartment and the My Moka family house come fully furnished, ensuring a home-like atmosphere.</p>
      <aside className='am-aside'>
        <div className='am-points faded-white'>
          <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Open year-round.</span>
        </div>

        <div className='am-points'>
          <span className='fa-am faded-white'><FaAngleRight/></span>
           <span>Check in from 12.00 Check out 11.00am</span>
        </div>

        <div className='am-points'>
        <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Complimentary WiFi throughout the House</span>
        </div>

        <div className='am-points'>
        <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Laundry service</span>
        </div>

        <div className='am-points'>
        <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Housekeeping Services:</span>
        </div>

        <div className='am-points'>
        <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Spacious Living and Dining Areas</span>
        </div>

        <div className='am-points'>
        <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Barbeque Facilities</span>
        </div>

        <div className='am-points'>
          <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Bathroom with hot and cold shower, and flush toilets</span>
        </div>

        <div className='am-points'>
          <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Hand wash basin</span>
        </div>

        <div className='am-points'>
          <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Secure Parking</span>
        </div>

        <div className='am-points'>
          <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Family-Friendly Environment</span>
        </div>

        <div className='am-points'>
          <span className='fa-am faded-white'><FaAngleRight/></span>
          <span>Fully Equipped Kitchen</span>
        </div>

        <div className='sq-btnDiv'>
          <Link to="/Houses/Book-room/homePageBooking">
            <button className="room-btn">< FaRegSquare className='fa-sqaure'/> BOOK A ROOM</button>
          </Link>
        </div>

      </aside>
    </div>

  </main>
} 