import React from 'react';

import { Link } from 'react-router-dom'

import ketLogo from "./Ketlogo.png"


export default function RetanToHouses() {
  return (
    <div className='sub-bar'>
      <div className='sub-bar-logo'><img src={ketLogo} alt='not found' className='ket-logo'/></div>
      <Link to="/Houses/Book-house/all-house" className='return-btn'>Return</Link>
            {/* <button>Return Home</button> */}
    </div>
  )
    
}