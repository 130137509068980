import photo1 from "../MamaKetImages/insideK1.jpg"
import photo2 from "../MamaKetImages/insideK2.jpg"
import photo3 from "../MamaKetImages/insideK3.jpg"
import photo4 from "../MamaKetImages/insideK4.jpg"
import photo5 from "../MamaKetImages/insideK5.jpg"
import photo6 from "../MamaKetImages/insideK6.jpg"
import photo7 from "../MamaKetImages/insideK7.jpg"
import photo8 from "../MamaKetImages/insideK8.jpg"
import photo9 from "../MamaKetImages/insideK9.jpg"
import photo10 from "../MamaKetImages/insideK10.jpg"
import photo11 from "../MamaKetImages/insideK11.jpg"
import photo12 from "../MamaKetImages/insideK12.jpg"
import photo13 from "../MamaKetImages/insideK13.jpg"



import Tphoto1 from "../MamaKetImages/outsideK1.jpg"
import Tphoto2 from "../MamaKetImages/outsideK2.jpg"
import Tphoto3 from "../MamaKetImages/outsideK3.jpg"
import Tphoto4 from "../MamaKetImages/outsideK4.jpg"
import Tphoto5 from "../MamaKetImages/outsideK5.jpg"
import Tphoto6 from "../MamaKetImages/outsideK6.jpg"
import Tphoto7 from "../MamaKetImages/outsideK7.jpg"
import Tphoto8 from "../MamaKetImages/outsideK8.jpg"
import Tphoto9 from "../MamaKetImages/outsideK9.jpg"
import Tphoto10 from "../MamaKetImages/outsideK10.jpg"
import Tphoto11 from "../MamaKetImages/outsideK11.jpg"
import Tphoto12 from "../MamaKetImages/outsideK12.jpg"
import Tphoto13 from "../MamaKetImages/outsideK13.jpg"
import Tphoto14 from "../MamaKetImages/outsideK14.jpg"
import Tphoto15 from "../MamaKetImages/outsideK15.jpg"
import Tphoto16 from "../MamaKetImages/outsideK16.jpg"
import Tphoto17 from "../MamaKetImages/outsideK17.jpg"
import Tphoto18 from "../MamaKetImages/outsideK18.jpg"
import Tphoto19 from "../MamaKetImages/outsideK19.jpg"
import Tphoto20 from "../MamaKetImages/outsideK20.jpg"
import Tphoto21 from "../MamaKetImages/outsideK21.jpg"
import Tphoto22 from "../MamaKetImages/outsideK22.jpg"
import Tphoto23 from "../MamaKetImages/outsideK23.jpg"


export const MamaKetImagesData = [
  {
    id: 1,
    photo: photo1
  },
  {
    id: 2,
    photo: photo2
  },
  {
    id: 3,
    photo: photo3
  },
  {
    id: 4,
    photo: photo4
  },
  {
    id: 5,
    photo: photo5
  },
  {
    id: 6,
    photo: photo6
  },
  {
    id: 7,
    photo: photo7
  },
  {
    id: 8,
    photo: photo8
  },
  {
    id: 9,
    photo: photo9
  },
  {
    id: 10,
    photo: photo10
  },
  {
    id: 11,
    photo: photo11
  },
  {
    id: 12,
    photo: photo12
  },
  {
    id: 13,
    photo: photo13
  },
  {
    id: 14,
    photo: Tphoto1
  },
  {
    id: 15,
    photo: Tphoto2
  },
  {
    id: 16,
    photo: Tphoto3
  },
  {
    id: 17,
    photo: Tphoto4
  },
  {
    id: 18,
    photo: Tphoto5
  },
  {
    id: 19,
    photo: Tphoto6
  },
  {
    id: 20,
    photo: Tphoto7
  },
  {
    id: 21,
    photo: Tphoto8
  },
  {
    id: 22,
    photo: Tphoto9
  },
  {
    id: 23,
    photo: Tphoto10
  },
  {
    id: 24,
    photo: Tphoto11
  },
  {
    id: 25,
    photo: Tphoto12
  },
  {
    id: 26,
    photo: Tphoto13
  },
  {
    id: 27,
    photo: Tphoto14
  },
  {
    id: 28,
    photo: Tphoto15
  },
  {
    id: 29,
    photo: Tphoto16
  },
  {
    id: 30,
    photo: Tphoto17
  },
  {
    id: 31,
    photo: Tphoto18
  },
  {
    id: 32,
    photo: Tphoto19
  },
  {
    id: 33,
    photo: Tphoto20
  },
  {
    id: 34,
    photo: Tphoto21
  },
  {
    id: 35,
    photo: Tphoto22
  },
  {
    id: 36,
    photo: Tphoto23
  },

]