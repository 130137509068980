import React from 'react';

import {FaArrowRight} from "react-icons/fa"


import Blacknavbar from '../../blackNavbar';
import BacktoTop from '../../Navbar/backtoTop';
import Booking from '../../Navbar/bookForm';
import Footer from '../../footer';

import Retan from '../return';

import Ket from "./allhouseIMAGES/ket.jpg"
import Mesa from "./allhouseIMAGES/mesa.jpg"


import { Link } from 'react-router-dom'

export default function Allhouse() {
  return (
    <main >
      <Blacknavbar/>
      <BacktoTop/>
      <Retan/>

      <div className='our-houses' data-aos="zoom-in">
        <h2 className='hseeeH1'>Our Houses</h2>  
        <div  className='hseee'></div>
      </div>      
      <section className='allhse-section'>
        <div className='the-allhse' data-aos="flip-right">
          <img src={Ket} className='the-allhse-img'/>
          <h4>My Muka House</h4>
          <span className='span-yw'>entire house</span>
          <p>100 to 80 USD 6-guests + breakfast / night</p>
          <Link to="/Houses/Book-house/houseFiles/bookKEThouse" className='view-li'>See Details</Link>
        </div>
        <div className='the-allhse' data-aos="flip-right">
          <img src={Mesa} className='the-allhse-img'/>
          <h4>My Moka House</h4>
          <span className='span-yw'>entire house</span>
          <p>70 USD 3-guests + breakfast / night</p>
          <Link to="/Houses/Book-house/houseFiles/bookMESAhouse" className='view-li'>See Details</Link>

        </div>

        
      </section>

      <div className='change-houses' data-aos="zoom-in">
      <Link to="/Houses/Book-room/all-room" className='change-houses-link'>
      <button><span className='tyyyy'>Go To Rooms</span> <FaArrowRight className='moveit'/> </button></Link>
      </div>

      <Booking/>
      <Footer/>
    </main>
  )
}