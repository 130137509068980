import React from 'react';
import axios from 'axios';

import Blacknavbar from '../../../blackNavbar';
import BacktoTop from '../../../Navbar/backtoTop';

import {FaSquareFull} from "react-icons/fa";
import {FaAngleRight} from "react-icons/fa";

// import { Link } from 'react-router-dom';


import RetanToRooms from '../../returnToRooms';
import Footer from '../../../footer';

import { useGlobalContext } from '../../../context';
import {FaTimes} from "react-icons/fa";

import stand1 from "../roomImages/standard/std1.jpg"
import stand2 from "../roomImages/standard/std2.jpg"
import stand3 from "../roomImages/standard/std3.jpg"
import stand4 from "../roomImages/standard/std4.jpg"
import stand5 from "../roomImages/standard/std5.jpg"
import stand6 from "../roomImages/standard/std6.jpg"
import stand7 from "../roomImages/standard/std7.jpg"
import stand8 from "../roomImages/standard/std8.jpg"
import stand9 from "../roomImages/standard/std9.jpg"
import stand10 from "../roomImages/standard/std10.jpg"
import stand11 from "../roomImages/standard/std11.jpg"
import { Link } from 'react-router-dom';



export default function Standardroom() {
  const {makeOder, setOder} = useGlobalContext();

  const [userMail, setMail] = React.useState("");
  const [reservedDays, setrsDays] = React.useState(0);
  const [startDay, setstDay] = React.useState("");
  const [endDay, setedDay] = React.useState("");
  const [clientName, setctName] = React.useState("");
  const [fee, setFee] = React.useState(0);

  let totalprice = 17 * reservedDays

  function PopOrder() {
    setOder(true)
  }

  // #####################################

  const [bookingData, setBooking] = React.useState({
    enterName: "",
    email: "",
    days: "",
    dayFrom: "",
    dayTo: ""
  })

  function GrabbookingData(event) {
    // event.preventDefault();
      setBooking(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
      
      setFee(totalprice)
  }
  React.useEffect(() => {
    setMail(bookingData.email);
    setctName(bookingData.enterName);
    setrsDays(bookingData.days);
    setstDay(bookingData.dayFrom);
    setedDay(bookingData.dayTo);
  });

  // ####################################
  let mail = ["nicksonnyongesa73@gmail.com", userMail]
  let nem = "Mama-KET Home Booking (standard room)"
  
  let paper1 = `Welcome ${clientName}!!,,,and thank you for choosing Mama KET homes (Standard Room). Your have reservation is for ${reservedDays} days as from ${startDay} to ${endDay}. Your total booking fee is $ ${fee}. Enjoy your stay!!`

  function sendMail() {
    axios.get("http://localhost:8081/", {
      params: {
        mail,
        nem,
        paper1,
      },
    })
    .then(() => {
      console.log("success");
    })
    .catch(() => {
      console.log("email not sent");
    })



  }

  // ####################################

  function CloseOdaform() {
    setOder(false)
  }

  // ####################################
  
  return (
    <main>
      <BacktoTop/>
      <Blacknavbar/>
      <section className='hse-mainSection'>
        <aside className='hse-background-stdRoom'>
          <RetanToRooms/>

          <div className='hse-mesaH'>
            <h1>Single Room With Balcony View</h1>
            <p className='hseP'>Where Comfort Meets Culture at Mama Ket Homes.</p>
          </div>

        </aside>

        <aside className='hse-Aside-div'>
          <div className='hse-imgside'>
            <img src={stand1} 
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className='hse-img1'/>
            
            <p>The <span className='bold-ttrr'>Single Room</span> at Mama Ket Homes offers a cozy and comfortable space, perfect for solo travelers or couples. Featuring a well-appointed bed, ample storage, and tasteful furnishings, this room provides a relaxing atmosphere for unwinding after a busy day in Arusha. The room comes with access to a shared living and dining area, as well as a fully equipped kitchen, giving you the convenience of home-like amenities. Whether you’re staying for a short visit or an extended trip, the Standard Room delivers a peaceful retreat with all the essentials for a comfortable stay.</p>
            
            <div className='hse-otherImg'>
              <img src={stand1} alt='not found' data-aos="flip-up"/>
              <img src={stand2} alt='not found' data-aos="flip-up"/>
              <img src={stand3} alt='not found' data-aos="flip-up"/>
              <img src={stand4} alt='not found' data-aos="flip-up"/>
              <img src={stand5} alt='not found' data-aos="flip-up"/>
              <img src={stand6} alt='not found' data-aos="flip-up"/>
              <img src={stand7} alt='not found' data-aos="flip-up"/>
              <img src={stand8} alt='not found' data-aos="flip-up"/>
              <img src={stand9} alt='not found' data-aos="flip-up"/>
              <img src={stand10} alt='not found' data-aos="flip-up"/>
              <img src={stand11} alt='not found' data-aos="flip-up"/>
         



            </div>
            <nav className='hse-msPoints'>
              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Check in from 12.00 Check out 11.00am</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Complimentary WiFi throughout the House</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Laundry service</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Housekeeping Services:</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Spacious Living and Dining Areas</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Barbeque Facilities</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Bathroom with hot and cold shower, and flush toilets</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Coffee/tea making facility</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Secure Parking</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Family-Friendly Environment</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Fully Equipped Kitchen</p>
              </div>
            </nav>
          </div>
          <section className='booside-sec'>
            <div className='hse-bookide'>
              <div className='hse-bookide-div1'>
                <aside>
                  <div className='bk-underscore'></div>
                  <h2>Price:$ 35.00 Per Night</h2>
                </aside>
                <p><span className='cm-bdroom'>Comfortable room:</span>Choose Single room; featuring comfortable bed, seating area and ample storage space.</p>
                <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>1 Guest</p>
                  </div>  

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>1 Bedroom</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>1 Single bed</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>Balcony</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    
                    <p className='ppwwp'>Breakfast</p>
                  </div>
                  
                </nav>
              <Link to="/Houses/Book-room/homePageBooking" className='book-btn'>BOOK</Link>
              </div>
              
              <div className='hse-bookide-div2'>
                <h2>Reservation</h2>
                <p>You can book online by clicking the <span>book-button</span>. For further enquiries and availability please visit our  contact page. It’s designed to help our valued guests find their ideal Mama Ket Homes experience.</p>
                <button className='cu-btn'>Contact us</button>
              </div>
            </div>
          </section>
          {/* <div className='hse-bookide'></div> */}

        </aside>
        
      </section>

       {/* ###################### */}

       <div className= {`${makeOder ? "OrderForm showForm": "OrderForm"}`}>
      
      <aside className='sidebar-Aside '>
      <button className='close-btn' onClick={CloseOdaform}>
          <FaTimes />
        </button>
        <div className='res-tittle'>
          <h4>Standard Room Reservation: $17 / Night</h4><hr/>
        </div>
        <div className='sidebar-Aside-div'>
          <label className='topN'>Name</label> <br/>
          <input 
              type="text"
              placeholder="Enter Name"
              name="enterName"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.enterName}
          /> <br/>

          <label>Email</label><br/>
          <input 
              type="email"
              placeholder="Enter Email"
              name="email"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.email}
          /> <br/>

          <label>Number of Nights</label><br/>
          <input 
              type="number"
              placeholder="Enter no of days"
              name="days"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.days}
            /> <br/>

          <label>From: </label>
          <input 
              type="date"
              name="dayFrom"
              placeholder="dd-mm-yyyy"
              className='inp-space'
              onChange={GrabbookingData}
              value={bookingData.tarehe}
          /> <br className='seperate-inp'/>
          <label>To: </label> 
          <input 
              type="date"
              name="dayTo"
              placeholder="dd-mm-yyyy"
              onChange={GrabbookingData}
              value={bookingData.tarehe}
          /> <br/>

          <h4 className='rm-price'>Total Price: ${totalprice}</h4>
      
          {/* <button type="submit" onClick={sendMail} className='submitBooking'>Submit</button> */}
          <hr/>
        </div>

      </aside>
    </div>

      {/* ###################### */}

      <Footer/>
      
    </main>
  )
}