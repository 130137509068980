import React from 'react';
import axios from 'axios';

import Blacknavbar from '../../../blackNavbar';
import BacktoTop from '../../../Navbar/backtoTop';

import {FaSquareFull} from "react-icons/fa";
import {FaAngleRight} from "react-icons/fa";


import { Link } from 'react-router-dom';


import RetanToRooms from '../../returnToRooms';
import Footer from '../../../footer';

import { useGlobalContext } from '../../../context';
import {FaTimes} from "react-icons/fa"

import dbr1 from "../roomImages/double/dbr1.jpg"
import dbr2 from "../roomImages/double/dbr2.jpg"
import dbr3 from "../roomImages/double/dbr3.jpg"
import dbr4 from "../roomImages/double/dbr4.jpg"
import dbr5 from "../roomImages/double/dbr5.jpg"
import dbr6 from "../roomImages/double/dbr6.jpg"
import dbr7 from "../roomImages/double/dbr7.jpg"
import dbr8 from "../roomImages/double/dbr8.jpg"
import dbr9 from "../roomImages/double/dbr9.jpg"
import dbr10 from "../roomImages/double/dbr10.jpg"
import dbr11 from "../roomImages/double/dbr11.jpg"


export default function Doubleroom() {
  const {makeOder, setOder} = useGlobalContext();

  const [userMail, setMail] = React.useState("");
  const [reservedDays, setrsDays] = React.useState(0);
  const [startDay, setstDay] = React.useState("");
  const [endDay, setedDay] = React.useState("");
  const [clientName, setctName] = React.useState("");
  const [fee, setFee] = React.useState(0);

  let totalprice = 23 * reservedDays

  function PopOrder() {
    setOder(true)
  }

  // #####################################

  const [bookingData, setBooking] = React.useState({
    enterName: "",
    email: "",
    days: "",
    dayFrom: "",
    dayTo: ""
  })

  function GrabbookingData(event) {
    // event.preventDefault();
      setBooking(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
      
      setFee(totalprice)
  }
  React.useEffect(() => {
    setMail(bookingData.email);
    setctName(bookingData.enterName);
    setrsDays(bookingData.days);
    setstDay(bookingData.dayFrom);
    setedDay(bookingData.dayTo);
  });

  // ####################################
  let mail = ["nicksonnyongesa73@gmail.com", userMail]
  let nem = "Mama-KET Home Booking (double room)"
  let paper1 = `Welcome ${clientName}!!,,,and thank you for choosing Mama KET home (Double Room). Your have reservation is for ${reservedDays} days as from ${startDay} to ${endDay}. Your total booking fee is $ ${fee}. Enjoy your stay!!`

  function sendMail() {
    axios.get("http://localhost:8081/", {
      params: {
        mail,
        nem,
        paper1,
      },
    })
    .then(() => {
      console.log("success");
    })
    .catch(() => {
      console.log("email not sent");
    })

  }

  // ####################################

  function CloseOdaform() {
    setOder(false)
  }

  // ####################################
  return (
    <main>
      <BacktoTop/>
      <Blacknavbar/>
      <section className='hse-mainSection'>
        <aside className='hse-background-double'>
          <RetanToRooms/>

          <div className='hse-mesaH'>
            <h1 data-aos="zoom-in">King Room With Balcony View</h1>
            <p data-aos="zoom-in" className='hseP'>Where Comfort Meets Culture at Mama Ket Homes.</p>
          </div>

        </aside>

        <aside className='hse-Aside-div'>
          <div className='hse-imgside'>
            <img src={dbr4} 
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className='hse-img1'/>
            
            <p data-aos="zoom-in">The <span className='bold-ttrr'>Double Room with Balcony View</span> at Mama Ket Homes is a perfect blend of comfort and charm. This spacious room features a large, comfortable bed, ideal for couples or solo travelers seeking extra space. One of its standout features is the private balcony, offering a serene view of the surrounding Lemara, Njiro area, perfect for enjoying your morning coffee or relaxing in the evening. The room is tastefully furnished, providing ample storage and access to shared amenities like the living and dining areas, as well as a fully equipped kitchen. This room is designed for those who value both comfort and a touch of outdoor relaxation during their stay.</p>

            
            <div className='hse-otherImg'>
              <img src={dbr1} alt='not found' data-aos="flip-up"/>
              <img src={dbr2} alt='not found' data-aos="flip-up"/>
              <img src={dbr3} alt='not found' data-aos="flip-up"/>
              <img src={dbr4} alt='not found' data-aos="flip-up"/>
              <img src={dbr5} alt='not found' data-aos="flip-up"/>
              <img src={dbr6} alt='not found' data-aos="flip-up"/>
              <img src={dbr7} alt='not found' data-aos="flip-up"/>
              <img src={dbr8} alt='not found' data-aos="flip-up"/>
              <img src={dbr9} alt='not found' data-aos="flip-up"/>
              <img src={dbr10} alt='not found' data-aos="flip-up"/>
              <img src={dbr11} alt='not found' data-aos="flip-up"/>
              
            </div>
            <nav className='hse-msPoints'>
              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Check in from 12.00 Check out 11.00am</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Complimentary WiFi throughout the House</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Laundry service</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Housekeeping Services:</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Spacious Living and Dining Areas</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Barbeque Facilities</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Bathroom with hot and cold shower, and flush toilets</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Coffee/tea making facility</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Secure Parking</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Family-Friendly Environment</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Fully Equipped Kitchen</p>
              </div>
            </nav>
          </div>
          <section className='booside-sec'>
            <div className='hse-bookide'>
              <div className='hse-bookide-div1'>
                <aside>
                  <div className='bk-underscore'></div>
                  <div>
                    <h4>36 USD - 1 guest</h4>
                    <h4>40 USD - 2 guests</h4>

                  </div>
                </aside>
                <p><span className='cm-bdroom'>Comfortable Bedrooms:</span>Choose King room; featuring comfortable beds, seating area and ample storage space.</p>

                <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>2 Guests</p>
                  </div>  

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>1 Bedroom</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>1 King bed</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>Balcony</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    
                    <p className='ppwwp'>Breakfast</p>
                  </div>
                  
                </nav>
                <Link to="/Houses/Book-room/homePageBooking" className='book-btn'>BOOK</Link>
              </div>
              
              <div className='hse-bookide-div2'>
                <h2>Reservation</h2>
                <p>You can book online by clicking the <span>book-button</span>. For further enquiries and availability please visit our  contact page. It’s designed to help our valued guests find their ideal Mama Ket Homes experience.</p>
                <button className='cu-btn'>Contact us</button>
              </div>
            </div>
          </section>
          {/* <div className='hse-bookide'></div> */}

        </aside>
        

      </section>
      {/* ###################### */}

      <div className= {`${makeOder ? "OrderForm showForm": "OrderForm"}`}>
      
      <aside className='sidebar-Aside '>
      <button className='close-btn' onClick={CloseOdaform}>
          <FaTimes />
        </button>
        <div className='res-tittle'>
          <h4>Double Room Reservation: $23 / Night </h4><hr/>
        </div>
        <div className='sidebar-Aside-div'>
          <label className='topN'>Name</label> <br/>
          <input 
              type="text"
              placeholder="Enter Name"
              name="enterName"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.enterName}
          /> <br/>

          <label>Email</label><br/>
          <input 
              type="email"
              placeholder="Enter Email"
              name="email"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.email}
          /> <br/>

          <label>Number of Nights</label><br/>
          <input 
              type="number"
              placeholder="Enter no of days"
              name="days"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.days}
            /> <br/>

          <label>From: </label>
          <input 
              type="date"
              name="dayFrom"
              placeholder="dd-mm-yyyy"
              className='inp-space'
              onChange={GrabbookingData}
              value={bookingData.tarehe}
          /> <br className='seperate-inp'/>
          <label>To: </label> 
          <input 
              type="date"
              name="dayTo"
              placeholder="dd-mm-yyyy"
              onChange={GrabbookingData}
              value={bookingData.tarehe}
          /> <br/>

          <h4 className='rm-price'>Total Price: ${totalprice}</h4>
      
          {/* <button type="submit" onClick={sendMail} className='submitBooking'>Submit</button> */}
          <hr/>
        </div>

      </aside>
    </div>

      {/* ###################### */}

      <Footer/>
      
    </main>
  )
}