import React from 'react';

import { Link } from 'react-router-dom'
import Navelements from './nave-elements';

import {FaAngleLeft, FaAngleRight} from "react-icons/fa"

import image1 from "./slidingImages/image1.jpg"
import image2 from "./slidingImages/image2.jpg"
import image3 from "./slidingImages/image3.jpg"
import image4 from "./slidingImages/image4.jpg"
import image5 from "./slidingImages/image5.jpg"
import image6 from "./slidingImages/image6.jpg"
import image7 from "./slidingImages/image7.jpg"

export default function Navigation() {
  const [imgNumber, setImgnumber] = React.useState(1)
  // const [timeOut, setTymeout] = React.useState("")

  const selectDiv = document.querySelectorAll(".the-img")
  
  function NextImg() {
      setImgnumber(prevImg => prevImg + 1) 
      // 
      
  }

  function PrevImg() {
      setImgnumber(prevImg=> prevImg - 1)
  }
  
  React.useEffect(()=> {
      if(imgNumber > selectDiv.length) {
          setImgnumber(prevImg=> 1)
      } else if (imgNumber === 0) {
          setImgnumber(prevImg=> selectDiv.length)
      }
      const timeOut = setTimeout(()=> setImgnumber(prevImg => prevImg + 1), 4000)

      return ()=> {
          clearTimeout(timeOut)
      }
      

  }, [imgNumber])

  
  

  const trans1 = {
      // transform: `translateX(-${(imgNumber - 1) * 500}px)`
      transform: `translateX(-${(imgNumber - 1) * 100}%)`
  }

  return (
    <main className='sliding-Maindiv'>
      
      <section className='sliding-img-section'>
        <div className='nav-elements'> <Navelements/> </div>
        <div className='sliding-img'>
          <img src={image1}  className='the-img' style={trans1} data-aos="zoom-in" alt='not found'/>
          
          <img src={image2}  className='the-img' style={trans1} data-aos="zoom-in" alt='not found'/>
        
          <img src={image3}  className='the-img' style={trans1} data-aos="zoom-in" alt='not found'/>
          
          <img src={image4}  className='the-img' style={trans1} data-aos="zoom-in" alt='not found'/>
      
          <img src={image5}  className='the-img' style={trans1} data-aos="zoom-in" alt='not found'/>
          
          <img src={image6}  className='the-img' style={trans1} data-aos="zoom-in" alt='not found'/>
      
          <img src={image7}  className='the-img' style={trans1} data-aos="zoom-in" alt='not found'/>
          
        </div>
        <div className='middle-content'>
          <FaAngleLeft className='angle-left angle' onClick={PrevImg}/>
          <div className='welcome angle'>
            <h2 data-aos="zoom-in" className='push-h2'>Welcome to Mama Ket Homes</h2>
            {/* <p className='welcome-more faded-white' data-aos="zoom-in">The property is located 3 km away from Njiro Complex. Arusha National Park is situated 45 km away and Mount Kilimanjaro is 90 km away. Arusha Airport is just a 10-minute drive away from the Appartment. Whether you’re looking for a comfortable apartment or a family-friendly home, Mama Ket Homes provides a relaxing retreat in Arusha.</p> */}
            <div className='wellllll'>
            <p className='welcome-mymuka' data-aos="zoom-in"><span className='wetttsp'>Mama Ket Homes </span> is located in the serene neighborhood of Lemara, Njiro, Arusha. The property features a selection of accommodations, including a spacious 3-bedroom <span className='wetttsp'>“My Muka,”</span> apartment with three distinct room options: a Single room, a King room, and a Triple room, offering flexibility and comfort for a variety of guests. Additionally, Mama Ket Homes offers a fully furnished house called<span className='wetttsp'>“My Moka,”</span> which is designed specifically for families. The My Moka house boasts 2 bedrooms, a well-equipped kitchen, a toilet, and a combined dining and sitting room, providing a cozy and convenient space for family gatherings or extended stays. Whether you’re looking for a comfortable apartment or a family-friendly home, Mama Ket Homes provides a relaxing retreat in Arusha.</p>
            
            </div>
          </div>
          <FaAngleRight className='angle-right angle' onClick={NextImg}/>
        </div>
      </section>

      <section className='the-mobile'>
        <div className=''> <Navelements/> </div>

        <div className='welc-tex'>
        <h2 className='p-h2' data-aos="zoom-in">Welcome to Mama Ket Homes!</h2>
            {/* <p className='welcome-more faded-white' data-aos="zoom-in">The property is located 3 km away from Njiro Complex. Arusha National Park is situated 45 km away and Mount Kilimanjaro is 90 km away. Arusha Airport is just a 10-minute drive away from the Appartment. Whether you’re looking for a comfortable apartment or a family-friendly home, Mama Ket Homes provides a relaxing retreat in Arusha.</p> */}
            <div className='wellllll'>
            <p className='welc-mymuka' data-aos="fade-up"><span className='wetttsp'>Mama Ket Homes </span> is located in the serene neighborhood of Lemara, Njiro, Arusha. The property features a selection of accommodations, including a spacious 3-bedroom <span className='wetttsp'>“My Muka,”</span> apartment with three distinct room options: a Single room, a King room, and a Triple room, offering flexibility and comfort for a variety of guests. Additionally, Mama Ket Homes offers a fully furnished house called<span className='wetttsp'>“My Moka,”</span> which is designed specifically for families. The My Moka house boasts 2 bedrooms, a well-equipped kitchen, a toilet, and a combined dining and sitting room, providing a cozy and convenient space for family gatherings or extended stays. Whether you’re looking for a comfortable apartment or a family-friendly home, Mama Ket Homes provides a relaxing retreat in Arusha.</p>
            
            </div>
        </div>

      </section>

    </main>
  )
}