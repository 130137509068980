import React from 'react';

// import { AppContext, useGlobalContext } from '../context';
import {FaArrowUp} from "react-icons/fa"

let calcScrollValue = ()=> {
  let scrollProgress = document.querySelector(".progress");
  let progressValue = document.querySelector(".progress-value");

  let pos = document.documentElement.scrollTop;
  let calcHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  let scrollValue = Math.round((pos * 100) / calcHeight)


  if (pos > 100) {
    scrollProgress.style.display = "grid";
  } else {
    scrollProgress.style.display = "none";
  }

  scrollProgress.addEventListener("click", ()=> {
    document.documentElement.scrollTop = 0;
  });

  scrollProgress.style.background = `conic-gradient(#c69731 ${scrollValue}%, #d7d7d7 ${scrollValue}%)`
};

window.onscroll = calcScrollValue;
window.onload = calcScrollValue;


export default function BacktoTop() {
  

  return (
    <div className='progress'>
        <span className='progress-value'>
          <FaArrowUp/>
        </span>
      </div>
  )
}