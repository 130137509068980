import React from 'react';

import BacktoTop from './Navbar/backtoTop';
import Blacknavbar from './blackNavbar';
import Footer from './footer';

import backImg from "./Background-images/backimg.jpg"
import ketLogo from "./Navbar/Ketlogo.png"

import { Link } from 'react-router-dom'


export default function About() {
  return (
    <main>
      <BacktoTop/>
      <Blacknavbar/>
      <div className='banner'>
        

        <div className='enqContainer'>
          <aside>
            <div className='return-om'>
              <Link to="/">
                <img src={ketLogo} className='about-logo' data-aos="flip-left"/>
              </Link>
              
              <div>
                <Link className='om-link' to={"/"}>
                  Home
                </Link>

                <Link className='om-link' to={"/Houses/Book-room/homePageBooking"}>
                  Book Room
                </Link>
              </div>
              
            </div>

            <div className='about-text'>
              <nav className='abt-extra'>
                <h2 className='faded-white' data-aos="zoom-in-down">About Us</h2>
                <div className='abs-unds' data-aos="zoom-in-down"></div>
                <p className='about-pge' data-aos="zoom-in-down"><p><span>Mama Ket Homes </span> is located in the serene neighborhood of Lemara, Njiro, Arusha. The property features a selection of accommodations, including a spacious 3-bedroom <span>“My Muka,”</span> apartment with three distinct room options: a Single room, a King room, and a Triple room, offering flexibility and comfort for a variety of guests. Additionally, Mama Ket Homes offers a fully furnished house called<span>“My Moka,”</span> which is designed specifically for families. The My Moka house boasts 2 bedrooms, a well-equipped kitchen, a toilet, and a combined dining and sitting room, providing a cozy and convenient space for family gatherings or extended stays. Whether you’re looking for a comfortable apartment or a family-friendly home, Mama Ket Homes provides a relaxing retreat in Arusha.</p></p>
              </nav>

            </div>
          </aside>
        </div>
      </div>
      <Footer/>
    </main>
  )
}