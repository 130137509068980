// import React from 'react';

// import useContext from 'react';

// const AppContext = React.createContext()

// const AppProvider = ({children}) => {
//   const [scrVal, setscrVal] = React.useState(0)

//   return <AppContext.Provider value={{scrVal, setscrVal}}>{children}</AppContext.Provider>
// }

// export const useGlobalContext = () => {
//   return useContext(AppContext)
// }

// export {AppContext, AppProvider}



import React, { useContext } from 'react'

const AppContext = React.createContext()

function AppProvider({children}) {
  const [scrVal, setscrVal] = React.useState(23)
  const [makeOder, setOder] = React.useState(false)
  return <AppContext.Provider 
  value={{scrVal, makeOder, setOder, setscrVal}}
  
  >{children}</AppContext.Provider>
} 

export function useGlobalContext() {
    return useContext(AppContext)
}
export {AppContext, AppProvider}