import React from 'react';
import axios from 'axios';

import Blacknavbar from '../../../blackNavbar';
import BacktoTop from '../../../Navbar/backtoTop';

import {FaSquareFull, FaAngleRight} from "react-icons/fa";
import { Link } from 'react-router-dom';


import RetanToRooms from '../../returnToRooms';
import Footer from '../../../footer';

import { useGlobalContext } from '../../../context';
import {FaTimes} from "react-icons/fa";

import tpr1 from "../roomImages/tripple/tpr1.jpg"
import tpr2 from "../roomImages/tripple/tpr2.jpg"
import tpr3 from "../roomImages/tripple/tpr3.jpg"
import tpr4 from "../roomImages/tripple/tpr4.jpg"
import tpr5 from "../roomImages/tripple/tpr5.jpg"
import tpr6 from "../roomImages/tripple/tpr6.jpg"
import tpr7 from "../roomImages/tripple/tpr7.jpg"
import tpr8 from "../roomImages/tripple/tpr8.jpg"
import tpr9 from "../roomImages/tripple/tpr9.jpg"
import tpr10 from "../roomImages/tripple/tpr10.jpg"
import tpr11 from "../roomImages/tripple/tpr11.jpg"
import tpr12 from "../roomImages/tripple/tpr12.jpg"
import tpr13 from "../roomImages/tripple/tpr13.jpg"
import tpr14 from "../roomImages/tripple/tpr14.jpg"
import tpr15 from "../roomImages/tripple/tpr15.jpg"


export default function Trippleroom() {
  const {makeOder, setOder} = useGlobalContext();

  const [userMail, setMail] = React.useState("");
  const [reservedDays, setrsDays] = React.useState(0);
  const [startDay, setstDay] = React.useState("");
  const [endDay, setedDay] = React.useState("");
  const [clientName, setctName] = React.useState("");
  const [fee, setFee] = React.useState(0);

  let totalprice = 7 * reservedDays

  function PopOrder() {
    setOder(true)
  }

  // #####################################

  const [bookingData, setBooking] = React.useState({
    enterName: "",
    email: "",
    days: "",
    dayFrom: "",
    dayTo: ""
  })

  function GrabbookingData(event) {
    // event.preventDefault();
      setBooking(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
      
      setFee(totalprice)
  }
  React.useEffect(() => {
    setMail(bookingData.email);
    setctName(bookingData.enterName);
    setrsDays(bookingData.days);
    setstDay(bookingData.dayFrom);
    setedDay(bookingData.dayTo);
  });

  // ####################################
  let mail = ["nicksonnyongesa73@gmail.com", userMail]
  let nem = "Mama-KET Home Booking (tripple room)"
  let paper1 = `Welcome ${clientName}!!,,,and thank you for choosing Mama KET homes (Tripple Room). Your have reservation is for ${reservedDays} days as from ${startDay} to ${endDay}. Your total booking fee is $ ${fee} per person. Enjoy your stay!!`

  function sendMail() {
    axios.get("http://localhost:8081/", {
      params: {
        mail,
        nem,
        paper1,
      },
    })
    .then(() => {
      console.log("success");
    })
    .catch(() => {
      console.log("email not sent");
    })


  }

  // ####################################

  function CloseOdaform() {
    setOder(false)
  }

  // ####################################
  return (
    <main>
      <BacktoTop/>
      <Blacknavbar/>
      <section className='hse-mainSection'>
        <aside className='hse-background-tripple'>
          <RetanToRooms/>

          <div className='hse-mesaH'>
            <h1 data-aos="zoom-in">Triple Room</h1>
            <p className='mmmo-white' data-aos="zoom-in">Where Comfort Meets Culture at Mama Ket Homes.</p>
          </div>

        </aside>

        <aside className='hse-Aside-div'>
          <div className='hse-imgside'>
            <img src={tpr1} 
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className='hse-img1'/>
            
            <p data-aos="zoom-in">The <span className='bold-ttrr'>Triple Room</span> at Mama Ket Homes is ideal for groups or families, offering three comfortable single beds, making it perfect for shared accommodation. Each bed is designed to provide privacy and comfort, ensuring a restful stay for every guest. This spacious room is tastefully furnished, with ample storage space for personal belongings. The pricing is flexible and budget-friendly, as it’s charged per person, per bed, per stay. Guests in the Triple Room also have access to shared amenities, including a living and dining area, and a fully equipped kitchen, ensuring a convenient and comfortable experience for all.</p>

            
            <div className='hse-otherImg'>
              <img src={tpr1} data-aos="flip-up" alt='not found'/>
              <img src={tpr2} data-aos="flip-up" alt='not found'/>
              <img src={tpr3} data-aos="flip-up" alt='not found'/>
              <img src={tpr4} data-aos="flip-up" alt='not found'/>
              <img src={tpr5} data-aos="flip-up" alt='not found'/>
              <img src={tpr6} data-aos="flip-up" alt='not found'/>
              <img src={tpr7} data-aos="flip-up" alt='not found'/>
              <img src={tpr8} data-aos="flip-up" alt='not found'/>
              <img src={tpr9} data-aos="flip-up" alt='not found'/>
              <img src={tpr10} data-aos="flip-up" alt='not found'/>
              <img src={tpr11} data-aos="flip-up" alt='not found'/>
              <img src={tpr12} data-aos="flip-up" alt='not found'/>
              <img src={tpr13} data-aos="flip-up" alt='not found'/>
              <img src={tpr14} data-aos="flip-up" alt='not found'/>
              <img src={tpr15} data-aos="flip-up" alt='not found'/>


            </div>
            <nav className='hse-msPoints'>
              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Check in from 12.00 Check out 11.00am</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Complimentary WiFi throughout the House</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Laundry service</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Housekeeping Services:</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Spacious Living and Dining Areas</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Barbeque Facilities</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Bathroom with hot and cold shower, and flush toilets</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Coffee/tea making facility</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Secure Parking</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Family-Friendly Environment</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Fully Equipped Kitchen</p>
              </div>
            </nav>
          </div>
          <section className='booside-sec'>
            <div className='hse-bookide'>
              <div className='hse-bookide-div1'>
                <aside>
                  <div className='bk-underscore'></div>
                  <div>
                    <h4>24 USD - 1 guest</h4>
                    <h4>27 USD - 2 guests</h4>
                    <h4>30 USD - 3 guests</h4>

                  </div>
                </aside>
                <p><span className='cm-bdroom'>Comfortable Bedrooms:</span>Choose triple room; featuring comfortable bed, seating area and ample storage space.</p>

                <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>3 Guests</p>
                  </div>  

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>1 Bedroom</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>3 Single bed</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>Balcony</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    
                    <p className='ppwwp'>Breakfast</p>
                  </div>
                  
                </nav>
                <Link to="/Houses/Book-room/homePageBooking" className='book-btn'>BOOK</Link>
              </div>
              
              <div className='hse-bookide-div2'>
                <h2>Reservation</h2>
                <p>You can book online by clicking the <span>book-button</span> For further enquiries and availability please visit our  contact page. It’s designed to help our valued guests find their ideal Mama Ket Homes experience.</p>
                <button className='cu-btn'>Contact us</button>
              </div>
            </div>
          </section>
          {/* <div className='hse-bookide'></div> */}

        </aside>
        

      </section>

      {/* ###################### */}

      <div className= {`${makeOder ? "OrderForm showForm": "OrderForm"}`}>
      
      <aside className='sidebar-Aside '>
      <button className='close-btn' onClick={CloseOdaform}>
          <FaTimes />
        </button>
        <div className='res-tittle'>
          <h4>Triple Room Reservation: $35 / Night</h4><hr/>
        </div>
        <div className='sidebar-Aside-div'>
          <label className='topN'>Name</label> <br/>
          <input 
              type="text"
              placeholder="Enter Name"
              name="enterName"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.enterName}
          /> <br/>

          <label>Email</label><br/>
          <input 
              type="email"
              placeholder="Enter Email"
              name="email"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.email}
          /> <br/>

          <label>Number of Nights</label><br/>
          <input 
              type="number"
              placeholder="Enter no of days"
              name="days"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.days}
            /> <br/>

          <label>From: </label>
          <input 
              type="date"
              name="dayFrom"
              placeholder="dd-mm-yyyy"
              className='inp-space'
              onChange={GrabbookingData}
              value={bookingData.tarehe}
          /> <br className='seperate-inp'/>
          <label>To: </label> 
          <input 
              type="date"
              name="dayTo"
              placeholder="dd-mm-yyyy"
              onChange={GrabbookingData}
              value={bookingData.tarehe}
          /> <br/>

          <h4 className='rm-price'>Total Price: ${totalprice}</h4>
      
          {/* <button type="submit" onClick={sendMail} className='submitBooking'>Submit</button> */}
          <hr/>
        </div>

      </aside>
    </div>

      {/* ###################### */}
      <Footer/>
      
    </main>
  )
}