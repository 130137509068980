import React from 'react';

import {FaTimes} from "react-icons/fa"


export default function Booking() {

  const [bookingData, setBooking] = React.useState({
    enterName: "",
    email: "",
    days: "",
    dayTo: "",
    dayFrom: ""


  })

  function GrabbookingData(event) {
    // event.preventDefault();
      setBooking(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
  }

  function CloseOdaform() {

  }

  console.log(bookingData)
  return (
    <main>
      <div className='OrderForm'>
      <button className='close-btn' onClick={CloseOdaform}>
          <FaTimes />
        </button>
      <aside className='.sidebar-Aside '>
        <label>Name</label>
        <input 
            type="text"
            placeholder="Enter Name"
            name="enterName"
            onChange={GrabbookingData}
            value={bookingData.enterName}
        /> <br/>

        <label>Email</label>
        <input 
            type="email"
            placeholder="Enter Email"
            name="email"
            onChange={GrabbookingData}
            value={bookingData.email}
        /> <br/>

        <label>Number of Days/Nights</label>
        <input 
            type="number"
            placeholder="Enter no of days for booking"
            name="no-of-days"
            onChange={GrabbookingData}
            value={bookingData.days}
        /> <br/>

        <label>From</label>
        <input 
            type="date"
            name="dayFrom"
            placeholder="dd-mm-yyyy"
            onChange={GrabbookingData}
            value={bookingData.tarehe}
        />
        <label>To

        <input 
            type="date"
            name="dayTo"
            placeholder="dd-mm-yyyy"
            onChange={GrabbookingData}
            value={bookingData.tarehe}
        />
        </label>
        <button type="submit" onClick={GrabbookingData}>Send Message</button>

      </aside>
    </div>
    </main>
  )
}