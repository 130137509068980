import React from 'react';
import axios from 'axios';

import Blacknavbar from '../../../blackNavbar';
import BacktoTop from '../../../Navbar/backtoTop';
import { Link } from 'react-router-dom';


import {FaSquareFull, FaAngleRight} from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RetanToHouses from '../../returnToHouses';

import { useGlobalContext } from '../../../context';
import {FaTimes} from "react-icons/fa"

import mesa1 from "./bkMESAimages/mesa1.jpg"
import mesa2 from "./bkMESAimages/mesa2.jpg"
import mesa3 from "./bkMESAimages/mesa3.jpg"
import mesa4 from "./bkMESAimages/mesa4.jpg"
import mesa5 from "./bkMESAimages/mesa5.jpg"
import mesa6 from "./bkMESAimages/mesa6.jpg"
import mesa7 from "./bkMESAimages/mesa7.jpg"
import mesa8 from "./bkMESAimages/mesa8.jpg"
import mesa9 from "./bkMESAimages/mesa9.jpg"
import mesa10 from "./bkMESAimages/mesa10.jpg"
import mesa11 from "./bkMESAimages/mesa11.jpg"
import mesa12 from "./bkMESAimages/mesa12.jpg"
import mesa13 from "./bkMESAimages/mesa13.jpg"
import mesa14 from "./bkMESAimages/mesa14.jpg"
import mesa15 from "./bkMESAimages/mesa15.jpg"
import mesa16 from "./bkMESAimages/mesa16.jpg"
import mesa17 from "./bkMESAimages/mesa17.jpg"
import mesa18 from "./bkMESAimages/mesa18.jpg"
import mesa19 from "./bkMESAimages/mesa19.jpg"
import mesa20 from "./bkMESAimages/mesa20.jpg"
import mesa21 from "./bkMESAimages/mesa21.jpg"
import mesa22 from "./bkMESAimages/mesa22.jpg"
import mesa23 from "./bkMESAimages/mesa23.jpg"
import Footer from '../../../footer';
  

export default function Bookmesa() {
  const {makeOder, setOder} = useGlobalContext();

  const [userMail, setMail] = React.useState("");
  const [reservedDays, setrsDays] = React.useState(0);
  const [startDay, setstDay] = React.useState("");
  const [endDay, setedDay] = React.useState("");
  const [clientName, setctName] = React.useState("");
  const [adultNo, setAdult] = React.useState(0);
  const [fee, setFee] = React.useState(0);

  let totalprice = 35 * reservedDays

  function PopOrder() {
    setOder(true)
  }

  // #####################################

  const [bookingData, setBooking] = React.useState({
    enterName: "",
    email: "",
    adults: "",
    days: "",
    dayFrom: "",
    dayTo: ""
  })

  function GrabbookingData(event) {
    // event.preventDefault();
      setBooking(prevResponse => {
          return {
              ...prevResponse,
              [event.target.name]: event.target.value

          }
      })
      
      // setFee(totalprice)
  }
  React.useEffect(() => {
    setMail(bookingData.email);
    setctName(bookingData.enterName);
    setAdult(bookingData.adults)
    setrsDays(bookingData.days);
    setstDay(bookingData.dayFrom);
    setedDay(bookingData.dayTo);
    setFee(totalprice)
  });

  // ####################################
  let mail = ["nicksonnyongesa73@gmail.com", userMail]
  let nem = "MESA House Booking"
  let paper1 = `Welcome ${clientName}!!,,,and thank you for choosing MESA house. Your reservation is for ${reservedDays} days as from ${startDay} to ${endDay}. Your total booking fee is $ ${fee}. Enjoy your stay!!`

  let guestName = clientName
  let totalPersons = adultNo
  let totalDays = reservedDays
  let amount = fee
  let firstDay = startDay
  let lastDay = endDay

  function sendMail() {
    axios.get("http://localhost:8081/mesa", {
      params: {
        mail,
        nem,
        guestName,
        totalPersons,
        totalDays,
        amount,
        firstDay,
        lastDay
      },
    })
    .then(() => {
      console.log("success");
    })
    .catch(() => {
      console.log("email not sent");
    })

    toast.success('🦄 Reservation made!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // transition: Bounce,
      });

  }

  // ####################################

  function CloseOdaform() {
    setOder(false)
  }

  // ####################################
  
  return (
    <main>
      <BacktoTop/>
      <Blacknavbar/>
      <section className='hse-mainSection'>
        <aside className='hse-background'>
          <RetanToHouses/>

          <div className='hse-mesaH'>
            <h1 data-aos="zoom-in">My Moka House</h1>
            <p data-aos="zoom-in" className='mmmo-white'>Where Comfort Meets Culture at Mama Ket Homes.</p>
          </div>

        </aside>

        <aside className='hse-Aside-div'>
          <div className='hse-imgside'>
            <img src={mesa15} 
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className='hse-img1'/>
            <h3 data-aos="zoom-in" className='hse-ffp'>My Moka House: Where Family Comfort Meets Home-Like Convenience</h3>
            <p data-aos="zoom-in">My Moka Arusha offers a home-like feeling in a peaceful setting surrounded by nature, just 10 minutes by car or 30 minutes by foot from the city center of Arusha. The property features 2 bedrooms and 1 bathroom, with all units including free WiFi. We provide additional services, including airport transfers "available upon payment" and safari arrangements. Whether you're here to relax, feel at home, or explore Arusha and the surrounding areas, My Moka Arusha is the perfect retreat. KARIBU! Each morning, we serve a fresh, hearty breakfast made from locally sourced ingredients to start your day right. Our breakfast options range from fresh fruits and pastries to traditional hot meals, prepared with care to satisfy all tastes. It's the perfect way to fuel up before a day of relaxation or adventure.</p>

            
            <div className='hse-otherImg'>
              <img src={mesa1} data-aos="flip-up" alt='not found'/>
              <img src={mesa2} data-aos="flip-up" alt='not found'/>
              <img src={mesa3} data-aos="flip-up" alt='not found'/>
              <img src={mesa4} data-aos="flip-up" alt='not found'/>
              <img src={mesa5} data-aos="flip-up" alt='not found'/>
              <img src={mesa6} data-aos="flip-up" alt='not found'/>
              <img src={mesa7} data-aos="flip-up" alt='not found'/>
              <img src={mesa8} data-aos="flip-up" alt='not found'/>
              <img src={mesa9} data-aos="flip-up" alt='not found'/>
              <img src={mesa10} data-aos="flip-up" alt='not found'/>
              <img src={mesa11} data-aos="flip-up" alt='not found'/>
              <img src={mesa12} data-aos="flip-up" alt='not found'/>
              <img src={mesa13} data-aos="flip-up" alt='not found'/>
              <img src={mesa15} data-aos="flip-up" alt='not found'/>
              <img src={mesa16} data-aos="flip-up" alt='not found'/>
              <img src={mesa17} data-aos="flip-up" alt='not found'/>
              <img src={mesa18} data-aos="flip-up" alt='not found'/>
              <img src={mesa19} data-aos="flip-up" alt='not found'/>
              <img src={mesa20} data-aos="flip-up" alt='not found'/>
              <img src={mesa21} data-aos="flip-up" alt='not found'/>
              <img src={mesa22} data-aos="flip-up" alt='not found'/>
              <img src={mesa23} data-aos="flip-up" alt='not found'/>


            </div>
            <nav className='hse-msPoints'>
              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Check in from 12.00 Check out 11.00am</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Complimentary WiFi throughout the House</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Laundry service</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Housekeeping Services:</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Spacious Living and Dining Areas</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Barbeque Facilities</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Bathroom with hot and cold shower, and flush toilets</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Coffee/tea making facility</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Secure Parking</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Family-Friendly Environment</p>
              </div>

              <div className='hse-msPoints-div'>
                <p className='Fa-para'><FaSquareFull/></p>
                <p>Fully Equipped Kitchen</p>
              </div>
            </nav>
          </div>
          <section className='booside-sec'>
            <div className='hse-bookide'>
              <div className='hse-bookide-div1'>
                <aside>
                  <div className='bk-underscore'></div>
                  <div>
                    <h4>70 USD / Night</h4>
                    <h4>3 guests</h4>

                  </div>
                </aside>
                <p><span className='cm-bdroom'>Comfortable Bedrooms:</span>Choose My Moka featuring comfortable beds and ample storage space.</p>

                <nav className='ppwwp-nav'>
                  
                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>3 Guests</p>
                  </div>  

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>2 Bedrooms</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>1 Livingroom</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>WiFi</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    <p className='ppwwp'>Balcony</p>
                  </div>

                  <div className='rum-amn'>
                    <span className='ppwwp-fa'><FaAngleRight/></span>
                    
                    <p className='ppwwp'>Breakfast</p>
                  </div>
                  
                </nav>
                <Link to="/Houses/Book-room/homePageBooking" className='book-btn'>BOOK</Link>
              </div>
              
              <div className='hse-bookide-div2'>
                <h2>Reservation</h2>
                <p>You can book online by clicking the <span>book-button</span>. For further enquiries and availability please visit our  contact page. It’s designed to help our valued guests find their ideal Mama Ket Homes experience.</p>
                <button className='cu-btn'>Contact us</button>
              </div>
            </div>
          </section>
          {/* <div className='hse-bookide'></div> */}

        </aside>
        

      </section>

       {/* ###################### */}

       <div className= {`${makeOder ? "OrderForm showForm": "OrderForm"}`}>
      
      <aside className='sidebar-Aside '>
      <button className='close-btn' onClick={CloseOdaform}>
          <FaTimes />
        </button>
        <div className='res-tittle'>
          <h4>Mesa House Reservation: $35 / Night</h4><hr/>
        </div>
        <div className='sidebar-Aside-div'>
          <label className='topN'>Name</label> <br/>
          <input 
              type="text"
              placeholder="Enter Name"
              name="enterName"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.enterName}
          /> <br/>

          <label>Email</label><br/>
          <input 
              type="email"
              placeholder="Enter Email"
              name="email"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.email}
          /> <br/>

          <label>Number of Adults: </label><br/>
          <input 
              type="number"
              placeholder="enter no"
              name="adults"
              min="1"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.adults}
          /> <br/>

          <label>Number of Nights</label><br/>
          <input 
              type="number"
              placeholder="Enter no of days"
              name="days"
              min="1"
              className='inp-width'
              onChange={GrabbookingData}
              value={bookingData.days}
            /> <br/>

          <label>From: </label>
          <input 
              type="date"
              name="dayFrom"
              placeholder="dd-mm-yyyy"
              className='inp-space'
              onChange={GrabbookingData}
              value={bookingData.tarehe}
          /> <br className='seperate-inp'/>
          <label>To: </label> 
          <input 
              type="date"
              name="dayTo"
              placeholder="dd-mm-yyyy"
              onChange={GrabbookingData}
              value={bookingData.tarehe}
          /> <br/>

          <h4>Total Price: $ {fee}</h4>
      
          <button type="submit" onClick={sendMail} className='submitBooking'>Submit</button>
          <hr/>
        </div>

      </aside>
    </div>

      {/* ###################### */}
      <Footer/>
      <ToastContainer/>
    </main>
  )
}