import React from 'react';

import interior from "./mesaHouseImages/interior.jpg"
import exterior from "./mesaHouseImages/exterior.jpg"

import { FaRegSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Mesahouse() {
  return <main className='Mesahouse'>
    <div className='mesa-hs-text'>
      <h5>Where Family Comfort Meets Home-Like Convenience</h5>
      <h1>My Moka House</h1>
      <div className='cozy-underscore'></div>
      <p><span>My Moka Arusha</span> is a thoughtfully designed, fully furnished home that caters specifically to families seeking comfort and convenience during their stay in Arusha. With 2 cozy bedrooms, a well-equipped kitchen, a clean and private toilet, and a combined dining and sitting room, the Moka offers the perfect space for relaxation and quality family time. Whether you’re cooking meals together in the kitchen or enjoying moments in the comfortable living area, the My Moka House  is tailored to feel like a true home away from home. Ideal for extended stays or family getaways, it provides everything needed for a seamless and enjoyable stay.</p>
      <Link to="/Houses/Book-house/houseFiles/bookMESAhouse">
        <button className='mesa-hs-btn1'>Explore More <FaRegSquare className='fa-sqaure'/></button>

      </Link>
    </div>
    {/* <div className='mesa-hs-images'>
      <img src={exterior} alt='not found' data-aos="zoom-in-up"/>
      <img src={interior} alt='not found' data-aos="zoom-out-up"/>
      <div className='mesa-hs-btn2'>
      <button >Explore More <FaRegSquare className='fa-sqaure'/></button>
      </div>

    </div> */}

    <div className='muka-imgsec'>
      <img src={exterior} alt='not found' data-aos="zoom-in-up"/>
      <img src={interior} alt='not found' data-aos="zoom-out-up"/>
      <div className='mesa-hs-btn2'>
      <button >Explore More <FaRegSquare className='fa-sqaure'/></button>
      </div>

    </div>
  </main>
} 